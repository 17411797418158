var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-point",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "viewBox": "0 0 60 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M30 17C24.4857 17 20 21.374 20 26.75C20 33.943 29.2264 42.4922 29.6192 42.8529C29.7266 42.9508 29.8633 43 30 43C30.1367 43 30.2734 42.9508 30.3809 42.8529C30.7736 42.4922 40 33.943 40 26.75C40 21.374 35.5143 17 30 17ZM30 32.1667C26.9368 32.1667 24.4444 29.7366 24.4444 26.75C24.4444 23.7634 26.9369 21.3333 30 21.3333C33.0631 21.3333 35.5556 23.7635 35.5556 26.75C35.5556 29.7365 33.0632 32.1667 30 32.1667Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }