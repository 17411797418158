import { render, staticRenderFns } from "./u-mobile-nav-body-basic.vue?vue&type=template&id=3d2b065f&scoped=true"
import script from "./u-mobile-nav-body-basic.vue?vue&type=script&lang=js"
export * from "./u-mobile-nav-body-basic.vue?vue&type=script&lang=js"
import style0 from "./u-mobile-nav-body-basic.vue?vue&type=style&index=0&id=3d2b065f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2b065f",
  null
  
)

export default component.exports