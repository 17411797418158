<template>
    <u-mobile-header-fixed-scroll>
        <u-mobile-header-body-basic>
            <template #left>
                <logo-primary>판심 법무법인</logo-primary>
            </template>
            <template #right>
                <v-row align="center" class="row--sm">
                    <v-col cols="auto">
                        <btn-hamburger-primary class="btn-hamburger--mobile"><span class="d-sr-only">모바일 메뉴 열기</span></btn-hamburger-primary>
                    </v-col>
                </v-row>
            </template>
        </u-mobile-header-body-basic>
    </u-mobile-header-fixed-scroll>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";

import UMobileHeaderBasic from "@/sets/styles/headers/u-mobile-header-basic.vue";
import UMobileHeaderFixedScroll from "@/sets/styles/headers/u-mobile-header-fixed-scroll.vue";
import UMobileHeaderHeadBasic from "@/sets/styles/headers/u-mobile-header-head-basic.vue";
import UMobileHeaderBodyBasic from "@/sets/styles/headers/u-mobile-header-body-basic.vue";
import UMobileHeaderFootBasic from "@/sets/styles/headers/u-mobile-header-foot-basic.vue";

export default {
    components: {
        LogoPrimary,
        BtnHamburgerPrimary,

        UMobileHeaderBasic,
        UMobileHeaderFixedScroll,
        UMobileHeaderHeadBasic,
        UMobileHeaderBodyBasic,
        UMobileHeaderFootBasic,
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-hamburger{
    ::v-deep{
        span,
        span::before,
        span::after{
            background-color: var(--v-grey-darken4) !important;
        }
    }
}
.mobile-header {
    &.scroll-active {
        ::v-deep{
            span,
            span::before,
            span::after{
                background-color: #fff !important;
            }
        }
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo.svg) !important;
                }
            }
        }
    }
}

.app--main{
    .mobile-header {
        ::v-deep{
            span,
            span::before,
            span::after{
                background-color: #fff !important;
            }
        }
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo.svg) !important;
                }
            }
        }
    }
}
</style>