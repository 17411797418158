var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "quick-menu quick-menu-fixed-bar"
  }, [_c('div', {
    staticClass: "quick-menu__inner"
  }, [_c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--pc"
  }), _c('ul', {
    staticClass: "quick-menu__buttons"
  }, [_c('li', [_c('quick-menu', {
    attrs: {
      "quickName": "quick-menu-pc"
    }
  })], 1), _c('li', {
    staticClass: "mt-20px"
  }, [_c('btn-top-secondary')], 1)])], 1), _c('sitemap-primary'), _c('inquiry-dialog', {
    ref: "inquiry-dialog"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }