var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.gnbName == 'sub-tab' ? _c('div', {
    class: _vm.gnbName
  }, [_vm._l(_vm.gnbs, function (item, i) {
    return [item.title == _vm.sh ? _c('tabs-primary', {
      key: i,
      attrs: {
        "color": "grey darken-4",
        "hide-slider": ""
      }
    }, _vm._l(item.children, function (child, j) {
      return _c('tab-primary', {
        key: j,
        class: {
          'v-tab--active': _vm.tabActive == child.title
        },
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 1) : _vm._e()];
  })], 2) : _vm.gnbName == 'gnb' ? _c('div', {
    class: _vm.gnbName + '-wrap'
  }, [_c('ul', {
    class: [_vm.gnbName, _vm.gnbClass]
  }, [_vm._l(_vm.gnbs, function (item, i) {
    return [!item.children ? _c('li', {
      key: i,
      class: _vm.gnbName + '__item'
    }, [item.href ? _c('a', {
      class: _vm.gnbName + '__link',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.title))]) : _c('router-link', {
      class: _vm.gnbName + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c(_vm.lnbTag, {
      tag: "component",
      staticClass: "lnb--empty"
    })], 1) : _c('li', {
      key: i,
      class: _vm.gnbName + '__item'
    }, [item.href ? _c('a', {
      class: _vm.gnbName + '__link ' + _vm.gnbName + '__link--toggle',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.title))]) : _c('router-link', {
      class: _vm.gnbName + '__link ' + _vm.gnbName + '__link--toggle',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c(_vm.lnbTag, {
      tag: "component"
    }, _vm._l(item.children, function (child, j) {
      return _c('li', {
        key: j
      }, [child.href ? _c('a', {
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  })], 2)]) : _vm.gnbName == 'gnb-mo' ? _c('div', {
    class: _vm.gnbName + '-wrap'
  }, [_c('ul', {
    class: [_vm.gnbName, _vm.gnbClass]
  }, [_vm._l(_vm.gnbs, function (item, i) {
    return [!item.children ? _c('li', {
      key: i,
      class: _vm.gnbName + '__item'
    }, [item.href ? _c('a', {
      class: _vm.gnbName + '__link',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.title))]) : _c('router-link', {
      class: _vm.gnbName + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: i,
      class: _vm.gnbName + '__item ' + _vm.gnbName + '__item--toggle'
    }, [_c('p', {
      class: _vm.gnbName + '__link ' + _vm.gnbName + '__link--toggle'
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-btn', {
      staticClass: "v-btn--text-fit",
      class: _vm.gnbName + '__arrow'
    }, [_c('icon-arrow-secondary', {
      attrs: {
        "size": "x-small",
        "direction": "down"
      }
    })], 1), _c('ul', {
      class: _vm.gnbName + '__sub'
    }, [_vm._l(item.children, function (child, j) {
      return [!child.children ? _c('li', {
        key: j,
        class: _vm.gnbName + '__sub-item'
      }, [child.href ? _c('a', {
        class: _vm.gnbName + '__sub-link',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        class: _vm.gnbName + '__sub-link',
        attrs: {
          "to": child.path
        }
      }, [_vm._v(" " + _vm._s(child.title) + " ")])], 1) : _c('li', {
        key: j,
        class: _vm.gnbName + '__sub-item'
      }, [child.href ? _c('a', {
        class: _vm.gnbName + '__sub-link ' + _vm.gnbName + '__sub-link--toggle',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        class: _vm.gnbName + '__sub-link ' + _vm.gnbName + '__sub-link--toggle',
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]), _c('ul', {
        class: _vm.gnbName + '__sub'
      }, _vm._l(child.children, function (grandChild) {
        return _c('li', {
          key: grandChild.title,
          class: _vm.gnbName + '__sub-item'
        }, [grandChild.href ? _c('a', {
          class: _vm.gnbName + '__sub-link',
          attrs: {
            "href": grandChild.href,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(grandChild.title))]) : _c('router-link', {
          class: _vm.gnbName + '__sub-link',
          attrs: {
            "to": grandChild.path
          }
        }, [_vm._v(_vm._s(grandChild.title))])], 1);
      }), 0)], 1)];
    })], 2)], 1)];
  })], 2)]) : _vm.gnbName == 'sitemap-menu' ? _c('div', {
    class: _vm.gnbName + '-wrap'
  }, [_c('ul', {
    class: [_vm.gnbName, _vm.gnbClass]
  }, [_vm._l(_vm.gnbs, function (item, i) {
    return [!item.children ? _c('li', {
      key: i,
      class: _vm.gnbName + '__item'
    }, [_c('span', {
      class: _vm.gnbName + '__item-num'
    }, [_vm._v("0" + _vm._s(i + 1))]), item.href ? _c('a', {
      class: _vm.gnbName + '__link',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.title))]) : _c('router-link', {
      class: _vm.gnbName + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))]), _c('p', {
      class: _vm.gnbName + '__link__txt'
    }, [_vm._v(_vm._s(item.titleKo))])])], 1) : _c('li', {
      key: i,
      class: _vm.gnbName + '__item'
    }, [_c('span', {
      class: _vm.gnbName + '__item-num'
    }, [_vm._v("0" + _vm._s(i + 1))]), item.href ? _c('a', {
      class: _vm.gnbName + '__link ' + _vm.gnbName + '__link--toggle',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]) : _c('router-link', {
      class: _vm.gnbName + '__link ' + _vm.gnbName + '__link--toggle',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))]), _c('p', {
      class: _vm.gnbName + '__link__txt'
    }, [_vm._v(_vm._s(item.titleKo))])]), _c('ul', {
      class: _vm.gnbName + '__sub'
    }, [_vm._l(item.children, function (child, j) {
      return [!child.children ? _c('li', {
        key: j,
        class: _vm.gnbName + '__sub-item'
      }, [child.href ? _c('a', {
        class: _vm.gnbName + '__sub-link',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        class: _vm.gnbName + '__sub-link',
        attrs: {
          "to": child.path
        }
      }, [_vm._v(" " + _vm._s(child.title) + " ")])], 1) : _c('li', {
        key: j,
        class: _vm.gnbName + '__sub-item'
      }, [child.href ? _c('a', {
        class: _vm.gnbName + '__sub-link ' + _vm.gnbName + '__sub-link--toggle',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        class: _vm.gnbName + '__sub-link ' + _vm.gnbName + '__sub-link--toggle',
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]), _c('v-btn', {
        staticClass: "v-btn--text-fit",
        class: _vm.gnbName + '__arrow'
      }, [_vm._t("arrow")], 2), _c('ul', {
        class: _vm.gnbName + '__sub'
      }, _vm._l(child.children, function (grandChild) {
        return _c('li', {
          key: grandChild.title,
          class: _vm.gnbName + '__sub-item'
        }, [grandChild.href ? _c('a', {
          class: _vm.gnbName + '__sub-link',
          attrs: {
            "href": grandChild.href,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(grandChild.title))]) : _c('router-link', {
          class: _vm.gnbName + '__sub-link',
          attrs: {
            "to": grandChild.path
          }
        }, [_vm._v(_vm._s(grandChild.title))])], 1);
      }), 0)], 1)];
    })], 2)], 1)];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }