<template>
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-tel" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M40.0742 33.7532C38.6048 33.7532 37.1621 33.5234 35.7949 33.0716C35.125 32.8431 34.3014 33.0527 33.8925 33.4726L31.194 35.5098C28.0644 33.8392 26.1367 31.9121 24.4889 28.806L26.4661 26.1778C26.9798 25.6648 27.164 24.9154 26.9433 24.2123C26.4895 22.8379 26.259 21.3959 26.259 19.9259C26.2591 18.8639 25.3952 18 24.3333 18H19.9258C18.8639 18 18 18.8639 18 19.9258C18 32.0977 27.9023 41.9999 40.0742 41.9999C41.1361 41.9999 42 41.136 42 40.0741V35.679C41.9999 34.6171 41.136 33.7532 40.0742 33.7532Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
}
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 60px;
    }
}
</style>