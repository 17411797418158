<template>
    <!-- <v-row v-bind="$attrs" align="center" :class="{ dark: dark }">
        <v-col cols="auto">
            <btn-arrow-primary :color="dark ? 'rgba(255, 255, 255, .6)' : 'grey lighten-3'" class="swiper-btn swiper-btn-prev" />
        </v-col>
        <v-col cols="auto">
            <span class="line"></span>
        </v-col>
        <v-col cols="auto">
            <btn-arrow-primary direction="right" :color="dark ? 'rgba(255, 255, 255, .6)' : 'grey lighten-3'" class="swiper-btn swiper-btn-next" />
        </v-col>
    </v-row> -->
    <v-row no-gutters>
        <v-col cols="6" md="auto">
            <v-btn color="primary lighten-4" block dark fab tile class="swiper-btn swiper-btn-prev">
                <icon-arrow-primary class="white--text" />
            </v-btn>
        </v-col>
        <v-col cols="6" md="auto">
            <v-btn color="primary lighten-3" block dark fab tile class="swiper-btn swiper-btn-next">
                <icon-arrow-primary direction="right" class="white--text" />
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
// import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    components: {
        // BtnArrowPrimary,
        IconArrowPrimary,
    },
    // props: {
    //     dark: { type: Boolean, default: false },
    // },
};
</script>

<style lang="scss" scoped>
// .line {
//     display: block;
//     width: 1px;
//     height: 16px;
//     background-color: var(--v-grey-lighten4);
// }
// .dark .line {
//     background-color: rgba(255, 255, 255, 0.3);
// }
@media (min-width: 768px) {
    .swiper-btn {
        --btn-height: 80px;
    }
}
@media (min-width: 1024px) {
    .swiper-btn {
        --btn-height: 120px;
    }
}
</style>