<template>
    <lnb class="lnb--all-border">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </lnb>
</template>

<script>
import Lnb from "@/components/publish/styles/headers/lnb.vue";

export default {
    props: {},
    components: {
        Lnb,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss">
.header__bg {
    background-color: rgba(255, 255, 255, 1) !important;
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
}
</style>
<style lang="scss" scoped>
.lnb--all-border ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    > li {
        > a {
            font-weight: 400;
            color: var(--v-grey-lighten1);
            padding: 8px;
            &:hover {
                color: var(--v-grey-darken4);
            }
        }
    }
}
.gnb__item {
    &.active {
        .lnb {
            border-left: 1px solid var(--v-grey-lighten3);
            border-right: 1px solid var(--v-grey-lighten3);
        }
    }
}
</style>
