export const lawyer = {    

    lawyer01: {
        title : "문유진",
        mainInfo: [
            [
                "전) 수원지방법원 판사",
                "전) 전주지방법원 판사",
                "제 49회 사법시험 합격",
                "서울대학교 법대 졸업",
                "과학고 조기졸업",
            ],
            [
                "카이스트 진학",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "서울대학교 법학대학 졸업",
                    "카이스트 진학",
                    "과학고 조기 졸업",
                    "제49회 사법시험 합격",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前수원지방법원 판사",
                    "前전주지방법원 판사",
                    "(성범죄, 아동학대 전담재판부)",
                    "(부패-횡령,배임,사기 전담재판부)",
                    "(교통전담재판부)",
                    "대법원 민사집행법연구회 ",
                    "전주지방법원 판례연구회 “의료소송의 쟁점과 핵심” 발제",
                    "수원지방법원 공동판례연구회 “부동산명의신탁” 발제",
                    "전주지방법원 가인마루음악회 진행",
                    "전주지방법원 사무관승진시험(6급 -> 5급) 강의 ",
                    "대한변호사협회 등록 형사전문변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "형사사건 검사제출서면(수사,공판,영장,보석) 검토건수 19,520건",
                    "형사(항소) 판결문, 결정문 작성건수 1,920건",
                    "민사-행정 판결문, 결정문 작성건수 3,368건",
                    "변호인 제출 서면 검토건수 26,440건",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "저서 판사의 마음: 성범죄편",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 서울대곡 초등학교 학교폭력 위원회",
                    "전 전주oo중학교 멘토법관",
                    "서이초 사건 유족 대리",
                    "양천구 신강초등학교 교사 폭행사건 대리",
                    "JTBC 사건반장 공무원스토킹사건 대리",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "의붓딸 화장품에 변기 세정제 주입 40대 항소심도 징역 1년",
                    "'음란물 차단 미흡' 혐의 前카카오 대표 무죄 확정",
                    "아동복지법위반(아동학대) 1심 징역 1년 → 항소심 징역 1년 6월 유죄 판결 확정 '양형 주장의 중요성'",
                    "현대자동차 신차디자인 도용 지적재산권 침해 관련 판결",
                    "하이마트 조세부과취소소송 판결",
                    "피겨스케이트 코치 집단아동학대 사건 판결",
                    "산불감시원 채용비리 오수봉 전 하남시장 판결",
                    "무효표 논란 김제수협 조합장 선거 현장검증",
                    "해경에 둔기 휘두른 중국 선원들 실형 선고",
                    "여자 속옷 훔치려던 공무원직 당연퇴직 관련 판결",
                    "정신병원 의료소송 환자보호 책임 인정 판결",
                    "음주사고 물적피해 경찰관 강등 행정판결",
                    "행정공무원 횡령 파면관련 행정판결",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "강간미수 불송치",
                    "음주운전 불송치",
                    "스토킹 불송치",
                    "명예훼손 불송치",
                    "피고인이 변호사의 공갈미수 무죄",
                    "강제추행 집행유예 기간 중 강제추행 구속영장 기각",
                ],
            },
        ], 
        class : "대표 변호사",
    },

    // lawyer02:{
    //     title : "이상옥",
    //     mainInfo: [
    //         [
    //             "서울대학교 법대 졸업",
    //             "제 46회 사법시험 합격",
    //             "26사단 군검찰관",
    //             "3사단 법무참모",
    //             "국군의무사령부 법무실장",
    //         ],
    //         [
    //             "[전] 법무법인 태평양 기업법무팀",
    //             "서울남부지방법원 국선 전담 변호사",
    //         ],
    //     ],
    //     info: [
    //         {
    //             title : "약력",
    //             infoDetail : [
    //                 "서울대학교 법학과 수석 졸업.",
    //                 "제 46회 사법시험 합격",
    //                 "사법연수원 36기",
    //                 "법무법인 태평양 기업법무팀",
    //                 "서울남부지방법원 국선전담변호사",
    //                 "법무법인 명도",
    //                 "現 판심법무법인 변호사",
    //             ],
    //         },
    //         {
    //             title : "",
    //             infoDetail : [
    //                 "대한변호사협회 부동산법 전문 변호사 ",
    //                 "임대차 관련법 전문 변호사",
    //                 "관세청장 표창 ",
    //             ],
    //         },
    //         {
    //             title : "",
    //             infoDetail : [
    //                 "前 수협,교보자산신탁, 새마을금고, 신협 등 금융사 명도 자문변호사 ",
    //                 "前 대기업 및 부동산 개발회사, 스타트업, 엔터테이먼트사 등 다수 기업 자문변호사,",
    //             ],
    //         },
    //         {
    //             title : "",
    //             infoDetail : [
    //                 "서울세관 관세부관처분취소 소송 수행",
    //                 "부산세관 관세부관처분취소 소송 수행",
    //                 "인천세관 관세부관처분취소 소송 수행",
    //             ],
    //         },
    //     ], 
    // },

    lawyer02:{
        title : "김상수",
        mainInfo: [
            [
                "서울대학교 경영학과 졸업",
                "제 41회 사법시험 합격",
                "대한변호사협회 권익위원회 위원",
                "[전] 한국거래소 근무 (1997년~2005년)",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "서울대학교 경영학과 졸업",
                    "제51회 사법시험 합격",
                    "사법연수원 41기 수료",
                    "한국거래소 근무",
                    "서울동부지방법원 조정위원",
                    "법무법인안세",
                    "現 판심법무법인 변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "대한변호사협회 권익위원회 위원",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 통일부 법률 자문 변호사",
                    "前 강남구립 논현노인복지관 자문변호사",
                    "前 엑센트리 자문변호사",
                    "前 엘아이에스 자문변호사",
                    "前 포바이오 자문변호사",
                    "前 가이아쓰리디 자문변호사",
                    "前 일진정공 자문변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "라임사모펀드 환매중단 관련 사기 및 불완전판매 사건 수행",
                    "㈜엘아이에스 한국거래소 공시위반 사건 수행",
                    "제이준코스메틱(주) 등 M&A 수행",
                    "***(주) 입찰참가자격 제한 취소 사건 수행",
                    "㈜**인베스트 주주권확인 사건 등 수행",
                ],
            },
        ], 
    },

    lawyer03:{
        title : "조현일",
        mainInfo: [
            [
                "서울대학교 경영학과 최우등 졸업",
                "[전] (주)하이투자증권",
                "[전] (주)현대자동차",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "서울대학교 경영대학 수석 졸업",
                    "중앙대학교 법학전문대학원 차석 입학",
                    "중앙대학교 법학전문대학원 법학동아리 민사법 연구회 활동 ",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 현대자동차 기업팀",
                    "前 하이투자증권 사내변호사 ",
                    "現 판심법무법인 변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "헌법재판소 성범죄 실무수습",
                    "서부지방검찰청 마약범죄전담 실무수습",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "부동산개발회사, 스타트업, 벤처캐피탈, 엔터테인먼트 등 기업 자문 다수",
                    "부동산 PF, 신탁법, 자본시장법, 공정거래법, 금융실명법, 노동법 법률자문 다수 ",
                    "자본시장법 위반 등 금융 범죄 고소, 피의사건 수행",
                    "투자자 보호의무 위반에 대한 증권사 상대 손해배상소송 수행",
                    "투자 손실에 대한 증권사 상대 손해배상소송 수행",
                    "미수채권 대여금반환청구 소송 수행",
                    "부동산, 예금, 예탁유가증권, 급여 가압류 등 보전처분 수행",
                    "경매, 회생절차 및 민사집행 수행",
                ],
            },
        ], 
    },

    lawyer04:{
        title : "김충현",
        mainInfo: [
            [
                
                "대법원 국선변호인",
                "'믿음의 법치' 법령경연대회 법무부장관상",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "대법원 국선변호인 ",
                    "군 인권자문변호사",
                    "법무부 입법대회 우수상 수상",
                    "교원자격증 취득",
                    "대한변호사협회 세무실무교육 수료",
                    "現 판심법무법인 수석변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "태양광발전소 업체 가공세금계산서 경찰조사단계 무혐의 결정, 조세심판단계 재조사 인용 결정",
                    "세무조사 절차 위반사건 거액 환급 인용 결정",
                    "렌터카업체 수십억원 부가세,종부세 등 양도소득세 직권취소 인용 결정",
                    "국세기본법,조세범처벌법 관련 헌법소원청구 인용",
                    "서이초 사건 유족 대리 ",
                    "100억 원 대 조세범사건 무혐의",
                ],
            },
        ], 
        class : "변호사",
    },

    lawyer05:{
        title : "이진형",
        mainInfo: [
            [
                "Army Commendation Medal(미 육군 표창 훈장)  ",
                "경희대학교 법과대학 수석 졸업",
                "건국대학교 법학전문대학원 석사 졸업",
                "변호사 시험 합격",
                "태정합동법률사무소",
                "現 판심법무법인 변호사",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "Army Commendation Medal(미 육군 표창 훈장)  ",
                    "경희대학교 법과대학 수석 졸업",
                    "건국대학교 법학전문대학원 석사 졸업",
                    "변호사 시험 합격",
                    "태정합동법률사무소",
                    "現 판심법무법인 변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "한국은행 주최 금융법논문 공모전 장려상 수상",
                    "KCLAA 법학전문대학원생 논문 공모전 수상",
                    "특허청 변리사 실무수습 집합교육 ",
                    "특허청 변리사 실무수습 성적우수상",
                    "특허청 국제지식재산연수원 실무수습 성적우수상",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 보건복지부, 중앙부처, 서울특별시, 구리시, 하남시 자문변호사",
                    "前 경기도 교육청, 학교안전공제회, 각종 공사 공공기관 자문변호사",
                    "前 SBI 저축은행 자문변호사",
                    "前 넥스플랜 주식회사 자문변호사",
                    "前 석정도시개발, 유진건설산업주식회사 자문변호사",
                ],
            },
        ], 
        class : "변호사·변리사",
    },

    lawyer06:{
        title : "정형욱",
        mainInfo: [
            [
                "한양대학교 정책학과 졸업",
                "한양대학교 형사법학회 학회장",
                "충남대학교 법학대학전문대학원 졸업",
                "경기남부법률사무소 연수",
                "심리상담사 1급",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "한양대학교 정책학과 졸업",
                    "한양대학교 형사법학회 학회장",
                    "충남대학교 법학대학전문대학원 졸업",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "경기남부법률사무소 연수",
                    "심리상담사 1급",
                ],
            },
        ], 
    },

    lawyer07:{
        title : "남기태",
        mainInfo: [
            [
                "성균관대학교 소비자가족/국제통상학과 졸업",
                "[전] (주) LG전자",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "성균관대학교 소비자/국제통상학과 졸업",
                    "중앙대학교 법학전문대학원 졸업",
                    "LG전자 한국영업본부 근무",
                    "신세계 백화점 영업관리부 근무",
                    "소비자업무전문가 자격 취득",
                    "헌법재판연구원 실무수습",
                    "現 판심법무법인 변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "하이마트 불법파견 노동 사건 수행",
                    "대기업 광고법 위반, 하도급법 위반 수행",
                ],
            },
        ], 
    },

    lawyer08:{
        title : "한상미",
        mainInfo: [
            [
                "검찰총장 표창",
                "서울 동부지방검찰청 검사",
                "인천지방검찰청 검사",
                "대전지방검찰청 검사",
                "청주지방검찰청 검사",
            ],
            [
                "대구지방검찰청 안동지청 검사",
                "법무법인 유한 광장 변호사",
                "UC BERKELEY LAW SCOOL",
                "서울동부지방검찰청<br class='d-none d-xl-block' /> 형사상고심의위원회 위원",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "서울동부지방검찰청 검사",
                    "서울동부지방검찰청 검사",
                    "청주지방검찰청 검사",
                    "인천지검 부천지청 검사",
                    "대구지검 안동지청 검사",
                    "대전지방검찰청 검사",
                    "제30기 사법연수원",
                    "제40회 사법시험 합격",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 로엘 법무법인 파트너변호사",
                    "前 법무법인(유한) 광장 변호사",
                    "現 판심법무법인 고문변호사",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "법제처 법령해석심의위원회 위원",
                    "국가보훈처 보훈심사위원회 위원",
                    "서울시 소청심사위원회 위원",
                    "서울시립대학교 법학전문대학원 겸임교수(형사실무)",
                    "한양대학교 법학전문대학원 겸임교수(형사모의재판)",
                    "서울동부지방검찰청 형사상고심의위원회 위원",
                ],
            },
        ], 
        class : "검사출신 고문 변호사",
    },

    lawyer09:{
        title : "한상현",
        mainInfo: [
            [
                "서울대 경제학부 졸업",
                "공인회계사",
                "(전) 삼일회계법인",
                "조세/회계 전문",
            ],
    ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "서울대 경제학부 졸업",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 삼일회계법인 감사  2009~2014",
                    "現 우영회계법인 등기이사 및 파트너 2015~",
                    "現 판심법무법인 고문회계사",
                ],
            },
        ], 
        class : "고문 회계사",
    },

    lawyer10:{
        title : "문수진",
        mainInfo: [
            [
                "소아청소년정신과 전문의",
                "의학박사/정신건강의학과 전문의/경희대학교 의과대학졸업",
                "한국정신분석학회 심층정신치료 고급훈련과정 졸업",
                "경희대학교 정신건강의학과 외래교수",
                "판심법무법인 협업 정신과전문의",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "소아청소년정신과 전문의",
                    "의학박사/정신건강의학과 전문의/경희대학교 의과대학졸업",
                    "한국정신분석학회 심층정신치료 고급훈련과정 졸업",
                    "경희대학교 정신건강의학과 외래교수",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "前 경희대학교병원 소아청소년 정신건강의학과 임상강사",
                    "前 가천대길병원 소아청소년정신과 진료교수",
                    "前 서울특별시 중부교육진원청 및 동대문구 정신보건센터 소아청소년 특별상담의",
                    "前 대학소아청소년정신의학회 총무위원 및 홍보위원",
                    "前 대동병원 소아청소년 병원학교장(나래학교)/대동 wee센터장",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "우수연구 논문상, 경희대학교 의학전문대학원 (2010)",
                    "중앙문화의학상, 대한신경정신의학회 (2008)",
                    "대한신경정신의학회 정회원",
                    "정신신체전문가 인증의",
                    "한국정신분석학회 정회원",
                    "대한소아청소년정신의학회 정회원",
                    "국제정신분석학회 IPA 정신분석가",
                    "판심법무법인 협업 정신과전문의",
                ],
            },
        ], 
        class : "정신건강의학과 전문의",
    },

    lawyer11:{
        title : "문현진",
        mainInfo: [
            [
                "부모와 아이 마음 의원",
                "대표원장 문 현 진 정신건강의학과 전문의",
                "판심법무법인 협업 정신과전문의",
            ],
        ],
        info: [
            {
                title : "약력",
                infoDetail : [
                    "부모와 아이 마음 의원",
                    "대표원장 문 현 진 정신건강의학과 전문의",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "가톨릭대학교 의과대학졸업",
                    "가톨릭대학교 의과대학 대학원 석,박사졸업",
                    "가톨릭중앙의료원 정신과 레지던트 수련",
                    "여의도 성모병원 임상강사",
                    "서울성모병원 정신건강의학과 외래교수",                    
                ],
            },
            {
                title : "",
                infoDetail : [
                    "대한소아청소년정신의학회 정회원",
                    "대한신경정신의학회 정회원",
                    "대한우울조울병학회 정회원",
                    "대한불만의학회 정회원",
                    "한국인지행동치료 정회원",
                    "대한정신약물학회 정회원",
                    "대한비만학회 정회원",
                ],
            },
            {
                title : "",
                infoDetail : [
                    "판심법무법인 협업 정신과전문의",
                ],
            },
        ], 
        class : "정신건강의학과 전문의",
    },

}
