<template>
    <main-section titWrapClass="text-left">
        <template #titHead>R</template>
        <template #tit>ecent Insights</template>
        <template #titLeft>
            <div class="recent-slide mb-20px mb-md-0">
                <swiper-button justify="end" />
            </div>
        </template>
        <swiper class="swiper swiper--recent" ref="swiperRecent" :options="swiperOptionsRecent">
            <!-- S: recent -->
            <template v-for="(insight, index) in insights">
                <swiper-slide data-aos="fade-up" :key="index">
                    <component :is="insight.url?.indexOf?.(`http`) < 0 ? `router-link` : `a`" :to="insight.url" :href="insight.url" target="_blank" class="recent-card plus-btn-card">
                        <v-row class="row--lg mx-0">
                            <v-col cols="12" class="px-0">
                                <div class="image-wrap"><img v-if="insight.image" :src="insight.image.src" alt="" class="d-block w-100" /></div>
                            </v-col>
                            <v-col cols="12" class="px-0">
                                <u-txt-default class="txt--sm txt--light line-height-14 mb-8px mb-md-16px">{{ insight.label }}</u-txt-default>
                                <u-tit-medium class="tit--xs line-height-14 text-truncate-2">{{ insight.subject }}</u-tit-medium>
                                <icon-plus class="mt-16px mt-md-32px" />
                            </v-col>
                        </v-row>
                    </component>
                </swiper-slide>
            </template>
            <!-- E: recent -->
        </swiper>
    </main-section>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSection from "@/sets/styles/mains/main-section.vue";
import SwiperButton from "@/components/publish/styles/swiper/swiper-button.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSection,
        SwiperButton,
        IconArrowPrimary,
        IconPlus,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data: () => {
        return {
            swiperOptionsRecent: {
                allowTouchMove: true,
                slidesPerView: 2,
                spaceBetween: 20,
                speed: 500,
                loop: false,
                navigation: {
                    prevEl: ".swiper-btn-prev",
                    nextEl: ".swiper-btn-next",
                },
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 100,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 120,
                    },
                },
            },

            insights: [],
        };
    },
    mounted() {
        api.v1.pansim.insights.gets().then(({ insights }) => (this.insights = insights));
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .swiper--recent {
        .swiper-wrapper {
            .swiper-slide:nth-child(2n - 1) {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    ::v-deep {
        .swiper--recent {
            &.swiper-container {
                overflow: visible;
            }
        }
    }
}
</style>