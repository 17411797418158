var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.quickName == 'quick-menu-pc' ? _c('ul', {
    class: _vm.quickName
  }, _vm._l(_vm.quick, function (item, key) {
    return _c('li', {
      key: key
    }, [_c('v-card', {
      staticClass: "quick-menu__button v-card--none-active-bg",
      attrs: {
        "to": item.to ? item.to : null,
        "href": item.href ? item.href : null,
        "target": item.href ? '_blank' : null,
        "flat": "",
        "tile": "",
        "color": "transparent"
      },
      on: {
        "click": function ($event) {
          return _vm.handleChatClick(item);
        }
      }
    }, [_c('icon-' + key, {
      tag: "component"
    }), _c('div', {
      staticClass: "quick-menu__button__open"
    }, [_c('u-tit-medium', {
      staticClass: "tit--xs white--text font-primary"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1)], 1);
  }), 0) : _vm.quickName == 'quick-menu-mo' ? _c('div', {
    class: _vm.quickName
  }, [_c('v-row', {
    staticClass: "quick-menu__button-group",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.quick, function (item, key) {
    return _c('v-col', {
      key: key,
      staticClass: "quick-menu__button-wrap"
    }, [_c('v-card', {
      staticClass: "quick-menu__button v-card--none-active-bg",
      attrs: {
        "to": item.to ? item.to : null,
        "href": item.href ? item.href : null,
        "target": item.href ? '_blank' : null,
        "flat": "",
        "tile": "",
        "dark": "",
        "color": "grey darken-4"
      },
      on: {
        "click": function ($event) {
          return _vm.handleChatClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "quick-menu__button__inner"
    }, [_c('icon-' + key, {
      tag: "component"
    }), _vm._v(" " + _vm._s(item.title) + " ")], 1)])], 1);
  }), 1)], 1) : _vm._e(), _c('inquiry-dialog', {
    ref: "inquiry-dialog"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }