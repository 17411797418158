<template>
    <div>
        
        <ul v-if="quickName == 'quick-menu-pc'" :class="quickName">
            <li v-for="(item, key) in quick" :key="key">
                <v-card
                :to="item.to ? item.to : null"
                :href="item.href ? item.href : null"
                :target="item.href ? '_blank' : null"
                flat tile color="transparent"
                class="quick-menu__button v-card--none-active-bg"
                @click="handleChatClick(item)">

                    <component :is="'icon-' + key" />
                    <div class="quick-menu__button__open">
                        <u-tit-medium class="tit--xs white--text font-primary">{{item.title}}</u-tit-medium>
                    </div>

                </v-card>
            </li>
        </ul>

        <div v-else-if="quickName == 'quick-menu-mo'" :class="quickName">
            <v-row no-gutters class="quick-menu__button-group">
                <v-col v-for="(item, key) in quick" :key="key" class="quick-menu__button-wrap">
                    <v-card
                        :to="item.to ? item.to : null"
                        :href="item.href ? item.href : null"
                        :target="item.href ? '_blank' : null"
                        flat tile dark color="grey darken-4"
                        class="quick-menu__button v-card--none-active-bg"
                        @click="handleChatClick(item)">
                        
                        <div class="quick-menu__button__inner">
                            <component :is="'icon-' + key" />
                            {{item.title}}
                        </div>

                    </v-card>
                </v-col>
            </v-row>
        </div>

        <inquiry-dialog ref="inquiry-dialog" />

    </div>
</template>

<script>
import InquiryDialog from "@/components/client/inquiry/inquiry-dialog.vue";
import IconChat from "@/components/publish/styles/icons/icon-chat.vue";
import IconTel from "@/components/publish/styles/icons/icon-tel.vue";
import IconPoint from "@/components/publish/styles/icons/icon-point.vue";
import IconBlog from "@/components/publish/styles/icons/icon-blog.vue";
import IconKakao from "@/components/publish/styles/icons/icon-kakao.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        quickName: String,
    },
    components: {
        InquiryDialog,
        IconChat,
        IconTel,
        IconPoint,
        IconBlog,
        IconKakao,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            quick: {
                chat:{
                    title: "빠른상담신청",
                },
                tel:{
                    title: "1660-0722",
                    href: "tel:16600722",
                },
                point:{
                    title: "오시는길",
                    to: "/contact/seoul",
                },
                blog:{
                    title: "블로그",
                    href: "https://blog.naver.com/myeugenei",
                },
                kakao:{
                    title: "카카오톡",
                    href: "http://pf.kakao.com/_xicPuG/chat",
                },
            },
        }
    },
    methods: {
        handleChatClick(item) {
            if (item === this.quick.chat) {
                this.$refs['inquiry-dialog'].shows = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.quick-menu-pc{
    .quick-menu__button{
        width: 80px;
        height: 60px;
        color: #fff;
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: center;
        &::after,
        &__open{
            position: absolute;
            width: 220px;
            height: 100%;
            top: 0;
            left: -220px;
        }
        &::after{
            content: "";
            display: block;       
            transform: scaleX(0);
            transform-origin: right center;
            background-color: var(--v-grey-darken3);
            border-left: 1px solid var(--v-secondary-base);  
            transition: .2s .1s ease-out;
        }
        &__open{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
        }
        &:hover{
            color: var(--v-secondary-base);
            &::after{
                transform: scaleX(1);         
                transition: .2s ease-out;
            }
            .quick-menu__button__open{
                opacity: 1;
                visibility: visible;
                transition: .2s .1s ease-out;
            }
        }
    }
}
.quick-menu-mo{
    .quick-menu__button{
        height: 100%;
        border-bottom: 1px solid var(--v-grey-darken3);;
        &-wrap{
            min-width: calc((100% - 2px) / 3);
        }
        &__inner{
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 1.4rem;
        }
        &-group{
            background-color: var(--v-grey-darken3);;
            gap: 1px;
        }
        ::v-deep{
            .icon--size-default{
                width: 38px !important;
            }
        }
    }
}
</style>
