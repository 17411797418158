<template>
    <v-dialog v-model="shows" max-width="546" width="100%">
        <v-card color="grey darken-4" class="v-dialog__inner rounded-0">
            <v-toolbar>
                <v-spacer></v-spacer>
                <v-btn icon x-large color="white" class="rounded-0" @click="close"><icon-close size="small" /></v-btn>
            </v-toolbar>
            <div class="v-dialog__contents px-20px px-md-48px pb-44px pb-md-48px white--text">
                <div class="text-center mb-16px mb-md-32px">
                    <u-tit-default class="tit--sm font-weight-bold line-height-14 white--text">고객상담신청</u-tit-default>
                    <u-txt-default class="txt--sm grey--text text--lighten-3 mt-4px mt-md-8px break-keep">1:1 온라인 상담 서비스를 통해 변호사의 조력을 받아보세요.</u-txt-default>
                </div>
                <div class="mb-16px mb-md-32px">
                    <v-img src="/images/common/inquiry-img.svg" max-width="450" :aspect-ratio="450 / 158" class="w-100 mx-auto mb-12px mb-md-24px"
                    alt="STEP 01: 온라인 상담 신청, STEP 02: 전문팀의 답변진행, STEP 03: 맞춤 법률 서비스 제공" />
                    <u-txt-default class="txt--sm grey--text text--lighten-3">
                        <v-row class="row--xxs">
                            <v-col v-for="(detail, index) in step" :key="index" cols="12">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">
                                        <strong class="font-weight-bold white--text">0{{index+1}}</strong>
                                    </v-col>
                                    <v-col>
                                        <p>{{detail}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </u-txt-default>
                </div>
                <inquiry-input @close="close"/>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import InquiryInput from "@/components/client/inquiry/inquiry-input.vue";
import IconClose from "@/components/publish/styles/icons/icon-close.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        InquiryInput,
        IconClose,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            shows: false,
            step: [
                "하단의 상담 신청란을 통해 상담 내용을 입력합니다.",
                "전문팀에서 의뢰인께 유선 연락을 드립니다.",
                "유선 연락을 통해 의뢰인 맞춤 법률서비스를 제공합니다.",
            ]
        }
    },
    methods:{
        close(){
            this.shows = false;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep{
    .v-dialog{
        &__inner{
            position: relative;
            padding-top: 44px;
            .v-toolbar{
                width: 100%;
                position: absolute;
                top: 0;
                background-color: transparent;
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: var(--v-secondary-base);
                top: 0;
                left: 0;
            }
        }
        &__contents{
            max-height: calc(100vh - (var(--container-gutter) * 2) - 48px);
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar{width: 4px; height: 4px;}
            &::-webkit-scrollbar-thumb{width: 4px; height: 4px; background-color: var(--v-secondary-base);}
            &::-webkit-scrollbar-track{width: 4px; height: 4px; background-color: var(--v-grey-lighten3);}
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){::v-deep{
    .v-dialog{
            &__inner{
                padding-top: 48px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
