var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-fixed-scroll', {
    staticClass: "header--lnb-all"
  }, [_c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("판심 법무법인")])];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }