var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "v": "",
      "viewBox": "0 0 10 32"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M10 5.12L9.125 6.016L5.625 2.432L5.625 32L4.375 32L4.375 2.432L0.874999 6.016L-6.08787e-07 5.12L5 6.66161e-07L10 5.12Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "50",
      "height": "16",
      "viewBox": "0 0 50 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M8 16L9.4 14.6L3.8 9L50 9L50 7L3.8 7L9.4 1.4L8 -3.67176e-06L6.99382e-07 8L8 16Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }