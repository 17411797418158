var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    attrs: {
      "titWrapClass": "text-left"
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("R")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("ecent Insights")];
      },
      proxy: true
    }, {
      key: "titLeft",
      fn: function () {
        return [_c('div', {
          staticClass: "recent-slide mb-20px mb-md-0"
        }, [_c('swiper-button', {
          attrs: {
            "justify": "end"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('swiper', {
    ref: "swiperRecent",
    staticClass: "swiper swiper--recent",
    attrs: {
      "options": _vm.swiperOptionsRecent
    }
  }, [_vm._l(_vm.insights, function (insight, index) {
    var _insight$url, _insight$url$indexOf;
    return [_c('swiper-slide', {
      key: index,
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_c(((_insight$url = insight.url) === null || _insight$url === void 0 ? void 0 : (_insight$url$indexOf = _insight$url.indexOf) === null || _insight$url$indexOf === void 0 ? void 0 : _insight$url$indexOf.call(_insight$url, `http`)) < 0 ? `router-link` : `a`, {
      tag: "component",
      staticClass: "recent-card plus-btn-card",
      attrs: {
        "to": insight.url,
        "href": insight.url,
        "target": "_blank"
      }
    }, [_c('v-row', {
      staticClass: "row--lg mx-0"
    }, [_c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "image-wrap"
    }, [insight.image ? _c('img', {
      staticClass: "d-block w-100",
      attrs: {
        "src": insight.image.src,
        "alt": ""
      }
    }) : _vm._e()])]), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--sm txt--light line-height-14 mb-8px mb-md-16px"
    }, [_vm._v(_vm._s(insight.label))]), _c('u-tit-medium', {
      staticClass: "tit--xs line-height-14 text-truncate-2"
    }, [_vm._v(_vm._s(insight.subject))]), _c('icon-plus', {
      staticClass: "mt-16px mt-md-32px"
    })], 1)], 1)], 1)], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }