<template>
    <sitemap class="sitemap--basic">
        <template #arrow>
            <icon-plus />
        </template>
    </sitemap>
</template>

<script>
import Sitemap from "@/sets/styles/headers/sitemap.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    props: {},
    components: {
        Sitemap,
        IconPlus,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .sitemap {
        &__contents {
            background-color: #fff;
        }
    }

    .sitemap-menu {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin: calc(var(--grid-gutter-xxl) * -1);
        &__item {
            flex: 0 0 25%;
            max-width: 25%;
            width: 100%;
            padding: var(--grid-gutter-xxl);
            > .sitemap-menu__sub {
                margin-top: 0;
                > .sitemap-menu__sub-item {
                    margin-top: 32px;
                    > .sitemap-menu__sub-link {
                        font-size: 1.8rem;
                        color: var(--v-grey-darken4);
                        &::before {
                            display: none;
                        }
                        &:hover {
                            color: var(--v-primary-base);
                        }
                    }
                }
            }
        }
        &__link {
            position: relative;
            display: inline-block;
            font-size: 2.8rem;
            font-weight: 700;
            color: var(--v-grey-darken4);
            margin-bottom: 40px;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                background-color: currentColor;
                opacity: 0;
                visibility: hidden;
                transition: all ease-out 0.2s;
            }
            &:hover {
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &__sub-item {
            position: relative;
            margin-top: 10px;
            &:first-child {
                margin-top: 0 !important;
            }

            &.open {
                .sitemap-menu {
                    &__sub {
                        margin-top: 20px;
                    }
                }
                > .sitemap-menu__sub-link,
                > .sitemap-menu__arrow {
                    color: var(--v-primary-base) !important;
                }
                > .sitemap-menu__arrow {
                    .icon-plus::after {
                        transform: translate(-50%, -50%) rotate(0);
                    }
                }
            }
        }
        &__sub-link {
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--v-grey-lighten1);
            transition: all ease-out 0.2s;
            &::before {
                content: "· ";
            }
            &:hover {
                color: var(--v-grey-darken4);
            }
        }
        &__sub-link--toggle {
            padding-right: 16px;
        }
        &__arrow {
            margin-top: -6px;
            width: 36px !important;
            height: 26px !important;
            padding: 0 !important;
        }
    }
}
</style>
