var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mobile-header-default', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "mobile-header--fixed mobile-header--fixed-scroll"
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }