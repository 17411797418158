var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('gnb', _vm._b({
    attrs: {
      "gnbClass": "gnb--topline"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'gnb', {
    gnbName: _vm.gnbName,
    gnbClass: _vm.gnbClass,
    lnbTag: _vm.lnbTag
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }