var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-text-fit', _vm._b({
    staticClass: "btn-top-secondary",
    attrs: {
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'u-btn-text-fit', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('div', [_c('v-divider', {
    staticClass: "white h-40px",
    attrs: {
      "vertical": ""
    }
  }), _c('span', {
    staticClass: "d-block mt-16px"
  }, [_vm._v("Top")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }