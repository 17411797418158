var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "546",
      "width": "100%"
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    staticClass: "v-dialog__inner rounded-0",
    attrs: {
      "color": "grey darken-4"
    }
  }, [_c('v-toolbar', [_c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-0",
    attrs: {
      "icon": "",
      "x-large": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-close', {
    attrs: {
      "size": "small"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-dialog__contents px-20px px-md-48px pb-44px pb-md-48px white--text"
  }, [_c('div', {
    staticClass: "text-center mb-16px mb-md-32px"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-bold line-height-14 white--text"
  }, [_vm._v("고객상담신청")]), _c('u-txt-default', {
    staticClass: "txt--sm grey--text text--lighten-3 mt-4px mt-md-8px break-keep"
  }, [_vm._v("1:1 온라인 상담 서비스를 통해 변호사의 조력을 받아보세요.")])], 1), _c('div', {
    staticClass: "mb-16px mb-md-32px"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto mb-12px mb-md-24px",
    attrs: {
      "src": "/images/common/inquiry-img.svg",
      "max-width": "450",
      "aspect-ratio": 450 / 158,
      "alt": "STEP 01: 온라인 상담 신청, STEP 02: 전문팀의 답변진행, STEP 03: 맞춤 법률 서비스 제공"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm grey--text text--lighten-3"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, _vm._l(_vm.step, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('strong', {
      staticClass: "font-weight-bold white--text"
    }, [_vm._v("0" + _vm._s(index + 1))])]), _c('v-col', [_c('p', [_vm._v(_vm._s(detail))])])], 1)], 1);
  }), 1)], 1)], 1), _c('inquiry-input', {
    on: {
      "close": _vm.close
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }