var render = function render(){
  var _vm$bodyRowAttrs, _vm$bodyDefaultAttrs, _vm$bodyLeftAttrs, _vm$bodyCenterAttrs, _vm$bodyRightAttrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', [_c('v-row', _vm._b({
    class: (_vm$bodyRowAttrs = _vm.bodyRowAttrs) === null || _vm$bodyRowAttrs === void 0 ? void 0 : _vm$bodyRowAttrs.className
  }, 'v-row', Object.assign({}, _vm.bodyRowAttrs), false), [this.$slots['default'] ? _c('v-col', _vm._b({
    class: (_vm$bodyDefaultAttrs = _vm.bodyDefaultAttrs) === null || _vm$bodyDefaultAttrs === void 0 ? void 0 : _vm$bodyDefaultAttrs.className
  }, 'v-col', Object.assign({}, _vm.bodyDefaultAttrs), false), [_vm._t("default")], 2) : _vm._e(), this.$slots['left'] ? _c('v-col', _vm._b({
    class: (_vm$bodyLeftAttrs = _vm.bodyLeftAttrs) === null || _vm$bodyLeftAttrs === void 0 ? void 0 : _vm$bodyLeftAttrs.className
  }, 'v-col', Object.assign({}, _vm.bodyLeftAttrs), false), [_vm._t("left")], 2) : _vm._e(), this.$slots['center'] ? _c('v-col', _vm._b({
    class: (_vm$bodyCenterAttrs = _vm.bodyCenterAttrs) === null || _vm$bodyCenterAttrs === void 0 ? void 0 : _vm$bodyCenterAttrs.className
  }, 'v-col', Object.assign({}, _vm.bodyCenterAttrs), false), [_vm._t("center")], 2) : _vm._e(), this.$slots['right'] ? _c('v-col', _vm._b({
    class: (_vm$bodyRightAttrs = _vm.bodyRightAttrs) === null || _vm$bodyRightAttrs === void 0 ? void 0 : _vm$bodyRightAttrs.className
  }, 'v-col', Object.assign({}, _vm.bodyRightAttrs), false), [_vm._t("right")], 2) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }