<template>
    <div class="footer-head">
        <v-container>
            <v-row v-bind="{ ...headRowAttrs }" :class="headRowAttrs?.className">
                <v-col v-if="this.$slots['default']" v-bind="{ ...headDefaultAttrs }" :class="headDefaultAttrs?.className">
                    <slot />
                </v-col>
                <v-col v-if="this.$slots['left']" v-bind="{ ...headLeftAttrs }" :class="headLeftAttrs?.className">
                    <slot name="left" />
                </v-col>
                <v-col v-if="this.$slots['center']" v-bind="{ ...headCenterAttrs }" :class="headCenterAttrs?.className">
                    <slot name="center" />
                </v-col>
                <v-col v-if="this.$slots['right']" v-bind="{ ...headRightAttrs }" :class="headRightAttrs?.className">
                    <slot name="right" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        headRowAttrs: { type: Object, default: () => ({}) },
        headDefaultAttrs: { type: Object, default: () => ({}) },
        headLeftAttrs: { type: Object, default: () => ({}) },
        headCenterAttrs: { type: Object, default: () => ({}) },
        headRightAttrs: { type: Object, default: () => ({}) },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
