<template>
    <main-section class="grey lighten-5" titWrapClass="main-section__head--sm text-center">
        <template #titHead>S</template>
        <template #tit>olutions</template>
        <div>
            <v-row align="center">
                <v-col cols="12" md="3">
                    <div class="pr-xl-96px text-center text-md-right">
                        <div v-for="(item, index) in solutionLeft" :key="index" :class="index !== 0 ? 'mt-16px mt-md-34px' : ''">
                            <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm font-weight-regular secondary--text mb-8px mb-md-16px">{{item.num}}</u-tit-default>
                            <u-tit-medium data-aos="fade-up" class="font-primary tit--sm line-height-14">{{item.title}}</u-tit-medium>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-img data-aos="zoom-out" data-aos-delay="400" src="/images/main/solutions-img.jpg" max-width="708" :aspect-ratio="708 / 660" class="w-100 mx-auto" />
                </v-col>
                <v-col cols="12" md="3">
                    <div class="pl-xl-96px text-center text-md-left">
                        <div v-for="(item, index) in solutionRight" :key="index" :class="index !== 0 ? 'mt-16px mt-md-34px' : ''">
                            <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm font-weight-regular secondary--text  mb-8px mb-md-16px">{{item.num}}</u-tit-default>
                            <u-tit-medium data-aos="fade-up" class="font-primary tit--sm line-height-14">{{item.title}}</u-tit-medium>
                        </div>                    
                    </div>                
                </v-col>
            </v-row>
            <div class="pt-40px pt-md-60px pt-lg-80px">
                <u-tit-default data-aos="fade-up" class="tit--lg line-height-14 text-center font-weight-regular font-primary">
                    단 <strong>‘1명’</strong>의 의뢰인을 위해 <strong>‘5명’</strong>의 변호사가 TF팀을 이루어 <br />
                    <strong class="primary--text text--lighten-3">당신을 보호합니다</strong>
                </u-tit-default>
            </div>
        </div>
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        MainSection,
        IconPlus,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            solutionLeft: [
                {
                    num : "01",
                    title : "대표 변호사 직접 상담",
                },
                {
                    num : "02",
                    title : "TF팀 구성",
                },
                {
                    num : "03",
                    title : "1차 Client 대면회의",
                },
                {
                    num : "04",
                    title : "내부 서면 검토",
                },
                {
                    num : "05",
                    title : "내부 회의",
                },
            ],
            solutionRight: [
                {
                    num : "06",
                    title : "2차 Client 대면회의",
                },
                {
                    num : "07",
                    title : "조사 시뮬레이션",
                },
                {
                    num : "08",
                    title : "법정 시뮬레이션",
                },
                {
                    num : "09",
                    title : "서면작성",
                },
                {
                    num : "10",
                    title : "행복한 일상",
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
</style>