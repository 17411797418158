<template>
    <txt v-bind="$attrs">
        <v-row class="row--xxs">
            <v-col cols="auto">
                ·
            </v-col>
            <v-col>
                <slot />
            </v-col>
        </v-row>
    </txt>
</template>

<script>
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        Txt,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
    .txt + .txt{
        margin-top: 4px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
