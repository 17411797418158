var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-chat",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "viewBox": "0 0 60 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M40.5001 18H25.5C24.6716 18 24 18.6716 24 19.5V22.5H34.5C36.1546 22.5 37.5 23.8455 37.5 25.5V34.25L40.5 36L39.5 31.5H40.5C41.3285 31.5 42 30.8283 42 29.9999V19.5C42 18.6716 41.3285 18 40.5001 18Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M34.5 24H19.5C18.6716 24 18 24.6715 18 25.5V36C18 36.8284 18.6716 37.5 19.5 37.5H23.5001L22.5001 42L30.2144 37.5H34.5001C35.3286 37.5 36.0001 36.8284 36.0001 36V25.5C36 24.6715 35.3285 24 34.5 24ZM23.25 33C22.8358 33 22.5 32.6641 22.5 32.25C22.5 31.8357 22.8358 31.4999 23.25 31.4999C23.6643 31.4999 24 31.8357 24 32.25C24 32.6642 23.6643 33 23.25 33ZM27 33C26.5858 33 26.25 32.6641 26.25 32.25C26.25 31.8357 26.5858 31.4999 27 31.4999C27.4143 31.4999 27.75 31.8357 27.75 32.25C27.75 32.6642 27.4143 33 27 33ZM30.75 33C30.3358 33 30 32.6641 30 32.25C30 31.8357 30.3358 31.4999 30.75 31.4999C31.1643 31.4999 31.5 31.8357 31.5 32.25C31.5 32.6642 31.1643 33 30.75 33Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }