var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('video', {
    staticClass: "main-visual__video",
    attrs: {
      "src": "/images/main/visual.mp4",
      "poster": "/images/main/visual-thumb.jpg",
      "loop": "",
      "autoplay": "",
      "muted": "",
      "playsinline": ""
    },
    domProps: {
      "muted": true
    }
  }), _c('div', {
    staticClass: "main-visual__inner"
  }, [_c('div', {
    staticClass: "main__inner"
  }, [_c('div', {
    staticClass: "for-pc"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', [_vm._v("1심 2심 판심! 판심법무법인")]), _vm._m(0), _c('div', {
    staticClass: "bottom-container1"
  }, [_vm._m(1), _c('div', {
    staticClass: "bottom-container2"
  }, [_c('div', {
    staticClass: "image-container"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n
    }, [_c('img', {
      attrs: {
        "src": `/images/main/main0${n}.png`,
        "alt": ""
      }
    })]);
  }), 0), _vm._m(2)])])])]), _c('div', {
    staticClass: "for-mobile"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', [_vm._v("1심 2심 판심! 판심법무법인")]), _vm._m(3), _c('div', {
    staticClass: "bottom-container1"
  }, [_vm._m(4), _c('div', {
    staticClass: "bottom-container2"
  }, [_c('div', {
    staticClass: "image-container"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n
    }, [_c('img', {
      attrs: {
        "src": `/images/main/main0${n}.png`,
        "alt": ""
      }
    })]);
  }), 0), _vm._m(5)])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_txt"
  }, [_vm._v(" 22년 퇴임 서울법대 판사출신변호사와"), _c('br'), _vm._v(" 24,23,22년 퇴임 검사출신변호사가"), _c('br'), _vm._v(" 의뢰인을 위해 전력을 다합니다. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-container"
  }, [_c('ul', {
    staticClass: "school-line-list border border1"
  }, [_c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_1.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v("서울법대 사시합격")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_2.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v("수원/전주지법 판사출신")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_3.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("서울북부/인천/청주지검 검사출신")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_4.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("대한변협 등록 형사법 전문")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('div', {
    staticClass: "image-set"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_5.png",
      "alt": ""
    }
  }), _c('img', {
    attrs: {
      "src": "/images/main/main_icon_6.svg",
      "alt": ""
    }
  })]), _c('p', [_vm._v("등 TV출연 변호사")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "last-container"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/searchbox.jpg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "low_txt pc"
  }, [_vm._v(" 당신의 변호사는 누구입니까?"), _c('br'), _vm._v(" 나의 변호사는 판심입니다. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_txt"
  }, [_vm._v(" 22년 퇴임 서울법대 판사출신변호사와"), _c('br'), _vm._v(" 24,23,22년 퇴임 검사출신변호사가"), _c('br'), _vm._v(" 의뢰인을 위해 전력을 다합니다. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-container"
  }, [_c('ul', {
    staticClass: "school-line-list border border1"
  }, [_c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_1.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v("서울법대 사시합격")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_2.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v("수원/전주지법 판사출신")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_3.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("서울북부/인천/청주지검 검사출신")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_4.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("대한변협 등록 형사법 전문")])]), _c('li', {
    staticClass: "school-line"
  }, [_c('div', {
    staticClass: "image-set"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/main_icon_5.png",
      "alt": ""
    }
  }), _c('img', {
    attrs: {
      "src": "/images/main/main_icon_6.svg",
      "alt": ""
    }
  })]), _c('p', [_vm._v("등 TV출연 변호사")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "last-container"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/searchbox.jpg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "low_txt pc"
  }, [_vm._v(" 당신의 변호사는 누구입니까?"), _c('br'), _vm._v(" 나의 변호사는 판심입니다. ")])]);

}]

export { render, staticRenderFns }