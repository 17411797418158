var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sitemap"
  }, [_c('div', {
    staticClass: "sitemap__contents"
  }, [_c('v-container', [_vm._t("sitemap-head"), _c('gnb', {
    attrs: {
      "gnbName": "sitemap-menu"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }), _vm._t("sitemap-foot")], 2), _c('logo-primary', {
    staticClass: "logo--light"
  }, [_vm._v("판심 법무법인")])], 1), _c('div', {
    staticClass: "sitemap__logo"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('logo-primary', {
    staticClass: "sitemap__logo__link logo--light"
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }