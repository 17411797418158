<template>
    <u-btn-text-fit v-bind="{ color, ...$attrs }" color="white" class="btn-top-secondary" @click="$vuetify.goTo(target, options)">
        <div>
            <v-divider vertical class="white h-40px" />
            <span class="d-block mt-16px">Top</span>
        </div>
    </u-btn-text-fit>
</template>

<script>
import UBtnTextFit from "@/components/publish/styles/buttons/u-btn-text-fit.vue";

export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {
        UBtnTextFit,
    },
    data: () => {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    mounted() {},
    methods: {},
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-secondary
.btn-top-secondary {
    &.v-size--default{
        font-size: 1.8rem !important;
        font-family: var(--font-secondary);
        font-weight: 400 !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
