<template>
    <mobile-header-head class="mobile-header-head--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-header-head>
</template>

<script>
import MobileHeaderHead from "@/sets/styles/headers/mobile-header-head.vue";

export default {
    props: {},
    components: {
        MobileHeaderHead,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-header-head--basic{
    background-color: var(--v-grey-lighten5);
    border-bottom: 1px solid var(--v-grey-lighten3);
}
</style>
