<template>
    <u-language-items-icon-text v-bind="$attrs" :type="'menu'">
        <slot />
    </u-language-items-icon-text>
</template>

<script>
import ULanguageItemsIconText from "@/components/publish/styles/headers/u-language-items-icon-text.vue";

export default {
    props: {
    },
    components: {
        ULanguageItemsIconText,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
