var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({}, 'u-tit-wrap-default', _vm.$attrs, false), [_c('u-tit-default', {
    staticClass: "tit--xxl",
    class: {
      'white--text': _vm.dark
    }
  }, [_c('span', {
    staticClass: "tit__head",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "300"
    }
  }, [_vm._t("TitHead")], 2), _c('span', {
    staticClass: "d-inline-block",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "100"
    }
  }, [_vm._t("default")], 2)]), this.$slots['TxtAdd'] ? _c('u-txt-default', {
    staticClass: "txt--sm"
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }