<template>
    <gnb v-bind="{ gnbName, gnbClass, lnbTag }" gnbClass="gnb--underline">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </gnb>
</template>

<script>
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,
    },
    components: {
        Gnb,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .gnb--underline {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        &::before {
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }
            }
            &__link {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    -webkit-transform: translate(-50%, 0);
                    -ms-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    display: block;
                    width: 0;
                    height: 2px;
                    background-color: var(--v-primary-base);
                    opacity: 0;
                    -webkit-transition: all ease-out 0.2s;
                    -ms-transition: all ease-out 0.2s;
                    transition: all ease-out 0.2s;
                }
            }
        }
    }
}
</style>
