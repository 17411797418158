var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-recent'), _c('main-lawyer'), _c('main-business'), _c('main-media'), _c('main-solutions'), _c('main-contact'), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }