var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "language-items"
  }, 'div', _vm.$attrs, false), [_vm.type == 'menu' ? [_c('div', {
    staticClass: "language-items__icon-text",
    attrs: {
      "id": _vm.id
    }
  }, [_vm.icon ? _c('u-icon', {
    staticClass: "language-items__icon"
  }, [_vm._v("language")]) : _vm._e(), _vm.text ? _c('span', {
    staticClass: "language-items__text"
  }, [_vm._v("KR")]) : _vm._e()], 1), _c('menu-primary', {
    attrs: {
      "activator": '#' + _vm.id,
      "location": "bottom center"
    }
  }, [_c('v-list', _vm._l(_vm.items, function (item, index) {
    return _c('v-list-item', {
      key: index + 'l',
      staticClass: "cursor-pointer"
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.country))])], 1);
  }), 1)], 1)] : _vm._e(), _vm.type == 'list' ? [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [index != 0 ? _c('v-col', {
      key: index + 'd',
      staticClass: "line-height-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      attrs: {
        "vertical": ""
      }
    })], 1) : _vm._e(), _c('v-col', {
      key: index + 'l',
      attrs: {
        "cols": "auto"
      }
    }, [_c('router-link', {
      staticClass: "language-items__icon-text",
      attrs: {
        "to": "/"
      }
    }, [_vm.icon ? _c('u-icon', {
      staticClass: "language-items__icon"
    }, [_vm._v("language")]) : _vm._e(), _vm.text ? _c('span', {
      staticClass: "language-items__text"
    }, [_vm._v(_vm._s(item.country))]) : _vm._e()], 1)], 1)];
  })], 2)] : _vm._e(), _vm.type == 'select' ? [_c('select-primary', {
    staticClass: "mw-100px",
    attrs: {
      "items": _vm.items,
      "item-text": "country",
      "item-value": "value"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$i18n, "locale", $$v);
      },
      expression: "$i18n.locale"
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }