var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section"
  }, [this.$slots['mainSectionHead'] ? _vm._t("mainSectionHead") : _c('v-container', [this.$slots['tit'] ? _c('v-row', {
    staticClass: "main-section__head",
    class: _vm.titWrapClass,
    attrs: {
      "no-gutters": "",
      "align": "end"
    }
  }, [_c('v-col', [_c('tit-wrap-primary', {
    staticClass: "pb-0",
    attrs: {
      "dark": _vm.dark
    },
    scopedSlots: _vm._u([{
      key: "TitHead",
      fn: function () {
        return [_vm._t("titHead")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2)], 1), this.$slots['titLeft'] ? _c('v-col', {
    staticClass: "mt-30px mt-lg-0",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "500"
    }
  }, [_vm._t("titLeft")], 2)]) : _vm._e()], 1) : _vm._e(), _vm._t("default")], 2), this.$slots['mainSectionFoot'] ? _vm._t("mainSectionFoot") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }