<template>
    <sitemap class="sitemap--outlined"></sitemap>
</template>

<script>
import Sitemap from "@/sets/styles/headers/sitemap.vue";

export default {
    props: {},
    components: {
        Sitemap,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .sitemap {
        &__contents {
            background-image: url(/images/common/pc-menu-bg.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            .container {
                display: flex;
                align-items: center;
                max-width: calc(var(--container) + var(--container-gutter)*2);
            }
        }
    }

    .sitemap-menu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        font-family: var(--font-secondary);
        margin: -30px -20px;
        height: 576px;
        &-wrap{
            width: 100%;
        }
        &__item {
            width: 50%;
            height: calc(100% / 4);
            padding: 30px 20px;
            position: relative;
            transform: scale(1.4);
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
            &-num{
                display: block;
                color: #fff;
                font-size: 2.8rem;
                line-height: 1;
                margin-bottom: 16px;
            }
        }
        &__link {
            position: relative;
            font-size: 5.6rem;
            font-weight: 700;
            transition: all ease-out 0.2s;
            display: flex;
            align-items: flex-end;
            >h2{   
                color: #fff;
                line-height: .7;             
                transition: all ease-out 0.2s;
            }
            &:hover {
                >h2{
                    color: rgba(255, 255, 255, 0);
                    -webkit-text-stroke: 1px #fff;
                }
            }
            &__txt{
                color: #fff;
                padding-left: 24px;
                line-height: 1;
                font-size: 2rem;
                font-weight: 500;
            }
        }
        &__sub {            
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            left: 20px;
            bottom: -4px;
        }
        &__sub-item {
            position: relative;
            &:not(:last-child){
                margin-right: 24px;
            }            
        }
        &__sub-link {
            display: block;
            font-size: 1.6rem;
            font-weight: 500;
            color: rgba(255, 255, 255, .6);
            transition: all ease-out 0.2s;
            &:hover {
                color: #fff;
            }
        }
        &__arrow {
            display: none;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    ::v-deep {
        .sitemap-menu {
            height: 620px;
            &__link {
                font-size: 7.2rem;
            }
            &__sub {            
                bottom: -10px;
            }
        }
    }

}

</style>
