<template>
    <header class="header">
        <slot />
        <div class="header__bg"></div>
    </header>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$nextTick(function () {
                this.header();
            });
        },

        // header
        header: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                // abc();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1200;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    // abc();
                }
            }

            // // gnb 호버
            // var gnb = document.querySelector(".gnb");
            // function gnbOver() {
            //     var header = document.querySelector(".header");
            //     header.classList.add("gnb-active");
            // }
            // function gnbOut() {
            //     var header = document.querySelector(".header");
            //     header.classList.remove("gnb-active");
            // }
            // gnb.addEventListener("mouseenter", gnbOver);
            // gnb.addEventListener("mouseleave", gnbOut);

            // // gnbItem 호버
            // var gnbItem = document.getElementsByClassName("gnb__item");
            // function gnbItemOver() {
            //     this.classList.add("active");
            // }
            // function gnbItemOut() {
            //     this.classList.remove("active");
            // }
            // for (var i = 0; i < gnbItem.length; i++) {
            //     gnbItem[i].addEventListener("mouseenter", gnbItemOver);
            //     gnbItem[i].addEventListener("mouseleave", gnbItemOut);
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;

    // bg
    &__bg {
        display: none;
    }

    // fixed
    &--fixed {
        position: fixed;
    }
}
@media (min-width: 1200px) {
    .header {
        // bg
        &__bg {
            position: fixed;
            top: calc(var(--header-head-height) + var(--header-body-height));
            left: 0;
            z-index: 1;
            width: 100vw;
            height: var(--header-bg-height);
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }

        // lnb-all
        &--lnb-all {
            ::v-deep {
                .lnb {
                    top: var(--header-body-height);
                    min-width: auto;
                    min-height: var(--header-bg-height);
                    height: auto;
                }
            }
            .header {
                &__bg {
                    display: block;
                }
            }
            // lnb-all gnb-active
            &.gnb-active {
                ::v-deep {
                    .header__bg,
                    .lnb {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
</style>
