<template>
    <u-select-filled-tile v-bind="$attrs" @searchKey="searchKey">
        <template #append> 
            <icon-arrow-secondary size="xx-large" direction="down" class="grey--text text--darken-4" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-select-filled-tile>
</template>

<script>
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import USelectFilledTile from "@/components/publish/styles/forms/u-select-filled-tile.vue";

export default {
    props: {},
    components: {
        IconArrowSecondary,
        USelectFilledTile,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {
        searchKey(searchKey) {
            this.$emit("searchKey", searchKey)
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
