<template>
    <div v-bind="$attrs" class="language-items">
        <template v-if="type == 'menu'">
            <div :id="id" class="language-items__icon-text">
                <u-icon v-if="icon" class="language-items__icon">language</u-icon>
                <!-- <span v-if="text" class="language-items__text">{{ $i18n.locale }}</span> -->
                <span v-if="text" class="language-items__text">KR</span>
            </div>
            <menu-primary :activator="'#' + id" location="bottom center">
                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index + 'l'" class="cursor-pointer">
                        <v-list-item-title>{{ item.country }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </menu-primary>
        </template>

        <template v-if="type == 'list'">
            <v-row align="center" class="row--sm">
                <template v-for="(item, index) in items">
                    <v-col v-if="index != 0" :key="index + 'd'" cols="auto" class="line-height-0"><v-divider vertical /></v-col>
                    <v-col :key="index + 'l'" cols="auto">
                        <router-link to="/" class="language-items__icon-text">
                            <u-icon v-if="icon" class="language-items__icon">language</u-icon>
                            <!-- <span v-if="text" class="language-items__text">{{ $i18n.locale }}</span> -->
                            <span v-if="text" class="language-items__text">{{ item.country }}</span>
                        </router-link>
                    </v-col>
                </template>
            </v-row>
        </template>

        <template v-if="type == 'select'">
            <select-primary v-model="$i18n.locale" :items="items" item-text="country" item-value="value" class="mw-100px" @input="input" />
        </template>
    </div>
</template>
<script>
import axios from "@/plugins/axios";

import MenuPrimary from "@/components/publish/parents/menus/menu-primary.vue";
import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {
        icon: Boolean,
        text: Boolean,
        type: { type: String, default: "list" },
        id: { type: String, default: "" },
    },
    components: {
        MenuPrimary,
        SelectPrimary,
        UIcon,
    },
    data: () => {
        return {
            items: [
                { country: "KR", value: "ko" },
                { country: "EN", value: "en" },
                { country: "CN", value: "cn" },
            ],
        };
    },
    methods: {
        input(locale) {
            axios.defaults.headers.common["Accept-Language"] = locale;
        },
    },
};
</script>

<style lang="scss" scoped>
.language-items {
    display: flex;
    align-items: center;
    color: var(--v-grey-darken4);

    &__icon-text,
    &__icon,
    &__text {
        color: inherit;
    }

    &__icon-text {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__icon {
        font-size: 2.4rem;
    }
    &__text {
        font-size: 1.4rem;
        text-transform: uppercase;
        padding-left: 2px;
    }

    > .row > .col {
        .v-divider {
            border-color: var(--v-grey-lighten3);
            height: 12px;
        }
    }

    &--light {
        color: #fff;
        .v-divider {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .language-items {
        &__text {
            font-size: 1.8rem;
        }
    }
}
</style>
