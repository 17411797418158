<template>
    <div class="mobile-header-body">
        <v-container>
            <v-row no-gutters align="center">
                <v-col v-if="this.$slots['default']">
                    <slot />
                </v-col>
                <v-col v-if="this.$slots['left']" cols="auto">
                    <slot name="left" />
                </v-col>
                <v-col v-if="this.$slots['center']">
                    <slot name="center" />
                </v-col>
                <v-spacer v-if="!this.$slots['center'] && this.$slots['right']" />
                <v-col v-if="this.$slots['right']" cols="auto">
                    <slot name="right" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-header-body {
    height: var(--header-body-height);
    .container {
        display: flex;
        align-content: center;
        height: 100%;
    }
}
</style>
