<template>
    <app-primary class="app--main">        
        <main-visual />
        <main-recent />
        <main-lawyer />
        <main-business />
        <main-media />
        <main-solutions />        
        <main-contact />
        <popup-layer />        
    </app-primary>
</template>

<script>
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import MainVisual from "@/sets/styles/mains/main-visual.vue";
import MainRecent from "@/sets/styles/mains/main-recent.vue";
import MainLawyer from "@/sets/styles/mains/main-lawyer.vue";
import MainBusiness from "@/sets/styles/mains/main-business.vue";
import MainMedia from "@/sets/styles/mains/main-media.vue";
import MainSolutions from "@/sets/styles/mains/main-solutions.vue";
import MainContact from "@/sets/styles/mains/main-contact.vue";

export default {
    components: {
        PopupLayer,
        AppPrimary,
        MainVisual,
        MainRecent,
        MainLawyer,
        MainBusiness,
        MainMedia,
        MainSolutions,
        MainContact,
    },
    props: {},
    data() {
        return {};
    },
};
</script>