<template>
    <mobile-nav-body class="mobile-nav-body--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-nav-body>
</template>

<script>
import MobileNavBody from "@/sets/styles/headers/mobile-nav-body.vue";

export default {
    props: {},
    components: {
        MobileNavBody,
    },
    data: () => {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$nextTick(function () {
                this.gnbMobile();
            });
        },

        // gnbMobile
        gnbMobile: function () {
            // gnb
            var gnbLinkToggle = document.querySelectorAll(".gnb-mo__link--toggle");
            gnbLinkToggle.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });
            // Mobile
            function toggleAccordion(el) {
                var gnbLinkToggle = document.querySelectorAll(".gnb-mo__link--toggle");
                var targetLi = el.currentTarget.parentNode.classList;
                // var target = el.currentTarget;

                el.preventDefault();
                if (targetLi.contains("open")) {
                    targetLi.remove("open");
                } else {
                    gnbLinkToggle.forEach(function (el) {
                        el.parentNode.classList.remove("open");
                    });
                    targetLi.add("open");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-nav-body--basic {
    flex: 0 1 auto;
    overflow-y: auto;
    ::v-deep {
        .container {
            padding: 0 !important;
        }

        .gnb-mo {
            &__item {
                border-bottom: 1px solid var(--border-color);
            }
            &__link {
                color: var(--v-grey-darken4) !important;
            }
            &__sub {
                background-color: var(--v-grey-lighten5);
                .gnb-mo__sub {
                    .gnb-mo__sub-link {
                        color: var(--v-grey-base) !important;
                    }
                }
            }
        }
    }
}
</style>
