import axios from "@/plugins/axios";

let url = "/api/console/pansim/insights";

export const insights = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    image: {
        post(image){
            let formData = new FormData();
            formData.append("image", image);
            return axios.post(`${url}/image`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => res.data);
        }
    }
}