var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-icon-outlined-tile', _vm._b({
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "btn-top-primary",
    attrs: {
      "fixed": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'u-btn-icon-outlined-tile', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-arrow-primary', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }