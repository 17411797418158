<template>
    <u-sitemap-outlined>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-sitemap-outlined>
</template>

<script>
import USitemapBasic from "@/sets/styles/headers/u-sitemap-basic.vue";
import USitemapLine from "@/sets/styles/headers/u-sitemap-line.vue";
import USitemapOutlined from "@/sets/styles/headers/u-sitemap-outlined.vue";
import USitemapSmall from "@/sets/styles/headers/u-sitemap-small.vue";

export default {
    components: {
        USitemapBasic,
        USitemapLine,
        USitemapOutlined,
        USitemapSmall,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
