<template>
    <mobile-nav-default class="mobile-nav--basic">
        <slot />
    </mobile-nav-default>
</template>

<script>
import MobileNavDefault from "@/sets/styles/headers/mobile-nav-default.vue";

export default {
    props: {},
    components: {
        MobileNavDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-nav--basic {
    .mobile-nav {
        &__contents {
            border-left: 1px solid var(--border-color);
        }
    }
}
</style>
