var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    attrs: {
      "titWrapClass": "text-left"
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("C")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("ontact Us")];
      },
      proxy: true
    }, {
      key: "titLeft",
      fn: function () {
        return [_c('v-img', {
          staticClass: "w-100",
          attrs: {
            "src": "/images/main/contact-object.svg",
            "width": _vm.$vuetify.breakpoint.mdAndUp ? 650 : '100%',
            "aspect-ratio": 650 / 80
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', [_c('v-row', {
    staticClass: "row--xl"
  }, _vm._l(_vm.contact, function (item, index) {
    return _c('v-col', {
      key: index,
      class: index !== 0 ? 'mt-20px mt-md-0' : '',
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "break-keep v-card--none-active-bg",
      class: index % 2 !== 0 ? 'mt-md-60px mt-lg-80px' : '',
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.lgAndUp ? 200 * (index + 1) : 200,
        "flat": "",
        "tile": "",
        "to": item.link
      }
    }, [_c('hover-line', {
      attrs: {
        "lineNone": !item.link
      },
      scopedSlots: _vm._u([{
        key: "foot",
        fn: function () {
          return [_c('div', {
            staticClass: "pt-12px pt-md-24px"
          }, [_c('u-tit-default', {
            staticClass: "tit--sm font-weight-regular grey--text text--lighten-1 mb-8px mb-md-16px"
          }, [item.subTitle ? _c('span', [_vm._v(_vm._s(item.subTitle))]) : _c('span', [_vm._v("0" + _vm._s(index + 1))])]), _c('u-tit-default', {
            staticClass: "tit--sm font-weight-medium line-height-14 font-primary"
          }, [_vm._v(" " + _vm._s(item.title) + " ")]), item.txt ? _c('u-txt-default', {
            staticClass: "line-height-17 mt-8px mt-md-16px"
          }, [_c('p', {
            domProps: {
              "innerHTML": _vm._s(item.txt)
            }
          })]) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": item.image,
        "aspect-ratio": 300 / 400
      }
    })], 1)], 1)], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }