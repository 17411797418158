var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "py-0",
    scopedSlots: _vm._u([{
      key: "mainSectionHead",
      fn: function () {
        return [_c('div', {
          staticClass: "lawyer lawyer--top"
        }, [_c('v-row', {
          staticClass: "lawyer__left",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-responsive', {
          staticClass: "w-100 h-100",
          attrs: {
            "aspect-ratio": 960 / 1056,
            "min-height": _vm.$vuetify.breakpoint.mdAndUp ? 900 : ''
          }
        }, [_c('div', {
          staticClass: "lawyer__img",
          style: 'background-image: url(' + _vm.repLawyer.mainImage + ')'
        })])], 1), _c('v-col', {
          staticClass: "d-none d-md-block",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-img', {
          staticClass: "w-100 h-100",
          attrs: {
            "src": "/images/main/lawyer-bg.jpg",
            "aspect-ratio": 960 / 1056,
            "min-height": _vm.$vuetify.breakpoint.mdAndUp ? 900 : ''
          }
        })], 1)], 1), _c('v-container', {
          staticClass: "lawyer__right"
        }, [_c('div', {
          staticClass: "py-40px py-md-0"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6",
            "offset-md": "6"
          }
        }, [_c('tit-wrap-primary', {
          staticClass: "lawyer__title pb-0",
          attrs: {
            "dark": ""
          },
          scopedSlots: _vm._u([{
            key: "TitHead",
            fn: function () {
              return [_vm._v("L")];
            },
            proxy: true
          }])
        }, [_vm._v("awyer ")]), _c('div', {
          staticClass: "lawyer__contents-wrap"
        }, [_c('v-card', {
          staticClass: "mb-20px mb-md-40px",
          staticStyle: {
            "width": "fit-content"
          },
          attrs: {
            "data-aos": "fade-up",
            "tile": "",
            "outlined": "",
            "color": "white"
          }
        }, [_c('div', {
          staticClass: "px-12px"
        }, [_c('u-tit-default', {
          staticClass: "tit--xl line-height-12 white--text font-weight-regular",
          staticStyle: {
            "font-family": "'Song Myung', serif"
          }
        }, [_c('span', {
          staticClass: "primary--text text--lighten-4"
        }, [_vm._v("판사 출신 ")]), _vm._v("대표 변호사 ")])], 1)]), _c('v-card', {
          staticClass: "lawyer__contents v-card--none-active-bg plus-btn-card",
          attrs: {
            "to": `/lawyer/${_vm.repLawyer._id}`,
            "tile": "",
            "flat": "",
            "color": "transparent"
          }
        }, [_c('u-tit-default', {
          staticClass: "tit--lg font-weight-bold white--text line-height-14 pb-8px pb-md-16px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200"
          }
        }, [_vm._v(" " + _vm._s(_vm.repLawyer.name) + " ")]), _c('v-row', {
          staticClass: "lawyer-info my-n2px my-md-n6px mx-md-n12px mx-lg-n30px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "400"
          }
        }, _vm._l(_vm.repLawyer.career, function (child, childIndex) {
          return _c('v-col', {
            key: childIndex,
            staticClass: "py-2px py-md-6px px-md-12px px-lg-30px",
            attrs: {
              "cols": "12",
              "md": "auto"
            }
          }, [_c('u-txt-dot', {
            staticClass: "white--text line-height-14"
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(child.txt)
            }
          })])], 1);
        }), 1), _c('div', {
          staticClass: "mt-40px mt-md-60px mt-lg-80px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "400"
          }
        }, [_c('icon-plus', {
          staticClass: "white--text"
        })], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('div', {
          staticClass: "lawyer lawyer--bottom overflow-hidden grey lighten-5"
        }, [_c('v-row', {
          staticClass: "lawyer__left",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "d-none d-md-block",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-img', {
          staticClass: "w-100 h-100",
          attrs: {
            "src": "/images/main/lawyer-bg2.jpg",
            "aspect-ratio": 960 / 576,
            "min-height": _vm.$vuetify.breakpoint.mdAndUp ? 576 : ''
          }
        })], 1), _c('v-col', {
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200",
            "cols": "12",
            "md": "6"
          }
        }, [_c('swiper', {
          ref: "swiperLeft",
          staticClass: "swiper swiper--left",
          attrs: {
            "options": _vm.swiperOptionsLeft
          }
        }, [_vm._l(_vm.listLawyers, function (item, key) {
          return [key !== 'lawyer01' ? _c('swiper-slide', {
            key: key
          }, [_c('v-responsive', {
            staticClass: "w-100 h-100",
            attrs: {
              "aspect-ratio": 960 / 576,
              "min-height": _vm.$vuetify.breakpoint.mdAndUp ? 576 : ''
            }
          }, [_c('div', {
            staticClass: "lawyer__img white",
            style: 'background-image: url(' + item.mainImage + ')'
          })])], 1) : _vm._e()];
        })], 2)], 1)], 1), _c('v-container', {
          staticClass: "lawyer__right"
        }, [_c('div', {
          staticClass: "py-40px py-md-0"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "lawyer__contents-wrap",
          attrs: {
            "data-aos": "fade-up"
          }
        }, [_c('swiper', {
          ref: "swiperRight",
          staticClass: "swiper swiper--right",
          attrs: {
            "options": _vm.swiperOptionsRight
          }
        }, [_vm._l(_vm.listLawyers, function (item, key) {
          return [key !== 'lawyer01' ? _c('swiper-slide', {
            key: key
          }, [_c('v-card', {
            staticClass: "lawyer__contents v-card--none-active-bg plus-btn-card",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "to": item.type === _vm.MEMBER_TYPES.LAWYER.value ? `/lawyer/${item._id}` : `/advisory/${item._id}`,
              "tile": "",
              "flat": "",
              "color": "transparent"
            }
          }, [_c('div', {
            staticClass: "pb-8px pb-md-16px"
          }, [_c('v-row', {
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('u-tit-default', {
            staticClass: "tit--lg font-weight-bold line-height-14"
          }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-divider', {
            staticClass: "grey lighten-1 w-40px w-md-80px"
          })], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('u-txt-medium', {
            staticClass: "txt--lg txt--dark line-height-14"
          }, [item.job ? _c('span', [_vm._v(_vm._s(item.job))]) : _c('span', [_vm._v("변호사")])])], 1)], 1)], 1), _c('v-row', {
            staticClass: "lawyer-info my-n2px my-md-n6px mx-md-n12px mx-lg-n30px"
          }, _vm._l(item.career, function (child, childIndex) {
            return _c('v-col', {
              key: childIndex,
              staticClass: "py-2px py-md-6px px-md-12px px-lg-30px",
              attrs: {
                "cols": "12",
                "md": "auto"
              }
            }, [_c('u-txt-dot', {
              staticClass: "line-height-14"
            }, [_c('span', {
              domProps: {
                "innerHTML": _vm._s(child.txt)
              }
            })])], 1);
          }), 1), _c('icon-plus', {
            staticClass: "mt-40px mt-md-60px mt-lg-80px"
          })], 1)], 1) : _vm._e()];
        })], 2)], 1)])], 1)], 1)]), _c('div', {
          staticClass: "lawyer--bottom__btn-group"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "lawyer--bottom__btn lawyer--bottom__btn--prev",
          attrs: {
            "color": "primary lighten-4",
            "block": "",
            "dark": "",
            "fab": "",
            "tile": ""
          }
        }, [_c('icon-arrow-primary')], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "lawyer--bottom__btn lawyer--bottom__btn--next",
          attrs: {
            "color": "primary lighten-3",
            "block": "",
            "dark": "",
            "fab": "",
            "tile": ""
          }
        }, [_c('icon-arrow-primary', {
          attrs: {
            "direction": "right"
          }
        })], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }