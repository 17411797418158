<template>
    <header-body class="header-body--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </header-body>
</template>

<script>
import HeaderBody from "@/sets/styles/headers/header-body.vue";

export default {
    props: {},
    components: {
        HeaderBody,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.header-body--basic{
    background-color: transparent;
    transition: 0.15s ease-out;
    position: relative;
    z-index: 101;
}
</style>
