var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "main-section--media"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "main-section--media__left",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex flex-md-column align-end align-md-start justify-space-between"
  }, [_c('tit-wrap-primary', {
    staticClass: "lawyer-slide__title pb-0",
    scopedSlots: _vm._u([{
      key: "TitHead",
      fn: function () {
        return [_vm._v("M")];
      },
      proxy: true
    }])
  }, [_vm._v("edia ")]), _c('div', {
    staticClass: "media-slide-btn",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "500"
    }
  }, [_c('swiper-button', {
    attrs: {
      "justify": "end"
    }
  })], 1)], 1)]), _c('v-col', {
    staticClass: "mt-30px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "media-contents",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('swiper', {
    ref: "swiperMedia",
    attrs: {
      "options": _vm.swiperOptionMedia
    }
  }, _vm._l(_vm.medias, function (item) {
    return _c('swiper-slide', {
      key: item.rank,
      staticClass: "swiper swiper--media"
    }, [_c('v-card', {
      staticClass: "media-card v-card--none-active-bg w-100 transparent",
      attrs: {
        "tile": "",
        "flat": ""
      },
      on: {
        "click": function ($event) {
          return _vm.open(item.videoId);
        }
      }
    }, [_c('v-responsive', {
      staticClass: "media-card__image w-100",
      style: 'background-image : url(' + item.thumbnail + ')',
      attrs: {
        "aspect-ratio": 830 / 466
      }
    })], 1)], 1);
  }), 1)], 1)])], 1), _c('v-bottom-sheet', {
    attrs: {
      "eager": "",
      "fullscreen": "",
      "content-class": "mw-100 h-100",
      "max-width": "100%",
      "width": "100%"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "10"
    },
    attrs: {
      "x-large": "",
      "absolute": "",
      "top": "",
      "right": "",
      "icon": "",
      "text": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-close')], 1), _c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "aspect-ratio": 1440 / 810
    }
  }, [_c('iframe', {
    staticClass: "w-100 h-100 d-block",
    attrs: {
      "src": 'https://www.youtube.com/embed/' + _vm.selectedVideo,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }