var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "grey lighten-5",
    attrs: {
      "titWrapClass": "main-section__head--sm text-center"
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("S")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("olutions")];
      },
      proxy: true
    }])
  }, [_c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pr-xl-96px text-center text-md-right"
  }, _vm._l(_vm.solutionLeft, function (item, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'mt-16px mt-md-34px' : ''
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-regular secondary--text mb-8px mb-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_vm._v(_vm._s(item.num))]), _c('u-tit-medium', {
      staticClass: "font-primary tit--sm line-height-14",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(_vm._s(item.title))])], 1);
  }), 0)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "400",
      "src": "/images/main/solutions-img.jpg",
      "max-width": "708",
      "aspect-ratio": 708 / 660
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pl-xl-96px text-center text-md-left"
  }, _vm._l(_vm.solutionRight, function (item, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'mt-16px mt-md-34px' : ''
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-regular secondary--text mb-8px mb-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_vm._v(_vm._s(item.num))]), _c('u-tit-medium', {
      staticClass: "font-primary tit--sm line-height-14",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(_vm._s(item.title))])], 1);
  }), 0)])], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px pt-lg-80px"
  }, [_c('u-tit-default', {
    staticClass: "tit--lg line-height-14 text-center font-weight-regular font-primary",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 단 "), _c('strong', [_vm._v("‘1명’")]), _vm._v("의 의뢰인을 위해 "), _c('strong', [_vm._v("‘5명’")]), _vm._v("의 변호사가 TF팀을 이루어 "), _c('br'), _c('strong', {
    staticClass: "primary--text text--lighten-3"
  }, [_vm._v("당신을 보호합니다")])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }