<template>
    <div class="quick-menu quick-menu-fixed-bar">
        <div class="quick-menu__inner">

            <btn-hamburger-primary class="btn-hamburger--pc" />

            <ul class="quick-menu__buttons">
                <li>
                    <quick-menu quickName="quick-menu-pc" />
                </li>
                <li class="mt-20px">
                    <btn-top-secondary />
                </li>
            </ul>
            
        </div>

        <sitemap-primary />
        <inquiry-dialog ref="inquiry-dialog" />

    </div>
</template>

<script>
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import QuickMenu from "@/sets/styles/quick-menu/quick-menu.vue";
import BtnTopSecondary from "@/components/publish/parents/buttons/btn-top-secondary.vue";
import SitemapPrimary from "@/sets/parents/headers/sitemap-primary.vue";
import InquiryDialog from "@/components/client/inquiry/inquiry-dialog.vue";
import IconChat from "@/components/publish/styles/icons/icon-chat.vue";
import IconTel from "@/components/publish/styles/icons/icon-tel.vue";
import IconPoint from "@/components/publish/styles/icons/icon-point.vue";
import IconBlog from "@/components/publish/styles/icons/icon-blog.vue";
import IconKakao from "@/components/publish/styles/icons/icon-kakao.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        BtnHamburgerPrimary,
        QuickMenu,
        SitemapPrimary,
        BtnTopSecondary,
        InquiryDialog,
        IconChat,
        IconTel,
        IconPoint,
        IconBlog,
        IconKakao,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            quick: {
                chat:{
                    title: "고객상담신청",
                },
                tel:{
                    title: "1660-0722",
                    href: "tel:16600722",
                },
                point:{
                    title: "오시는길",
                    to: "/contact/seoul",
                },
                blog:{
                    title: "블로그",
                    href: "https://blog.naver.com/myeugenei",
                },
                kakao:{
                    title: "카카오톡",
                    href: "http://pf.kakao.com/_hsxfxgxj/chat",
                },
            },
        }
    },
    methods: {
        handleChatClick(item) {
            if (item === this.quick.chat) {
                this.$refs['inquiry-dialog'].shows = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.quick-menu{
    position: fixed;
    width: 80px;
    height: 100vh;
    right: 0;
    z-index: 102;
    &__inner{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;            
            background-color: rgba(38, 46, 55, .9);            
            z-index: 6;
        }
    }
    &__buttons{
        position: relative;
        z-index: 6;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__button{
        width: 80px;
        height: 60px;
        color: #fff;
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: center;
        &::after,
        &__open{
            position: absolute;
            width: 220px;
            height: 100%;
            top: 0;
            left: -220px;
        }
        &::after{
            content: "";
            display: block;       
            transform: scaleX(0);
            transform-origin: right center;
            background-color: var(--v-grey-darken3);
            border-left: 1px solid var(--v-secondary-base);  
            transition: .2s .1s ease-out;
        }
        &__open{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
        }
        &:hover{
            color: var(--v-secondary-base);
            &::after{
                transform: scaleX(1);         
                transition: .2s ease-out;
            }
            .quick-menu__button__open{
                opacity: 1;
                visibility: visible;
                transition: .2s .1s ease-out;
            }
        }
    }
}
</style>