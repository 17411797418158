<template>
    <u-tit-wrap-default v-bind="$attrs">
        <u-tit-default class="tit--xxl" :class="{ 'white--text': dark }">
            <span data-aos="zoom-out" data-aos-delay="300" class="tit__head"><slot name="TitHead" /></span><span data-aos="zoom-out" data-aos-delay="100" class="d-inline-block"><slot /></span>
        </u-tit-default>
        <u-txt-default v-if="this.$slots['TxtAdd']" class="txt--sm"><slot name="TxtAdd" /></u-txt-default>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false, },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit__head{
    display: inline-block;
    font-size: 8rem;
    color: var(--v-primary-lighten4);
    line-height: var(--tit-font-size-xxl);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit__head{
        font-size: 12rem;
    }
}
@media (min-width:1024px){
    .tit__head{
        font-size: 16rem;
    }
}
@media (min-width:1200px){
    .tit__head{
        font-size: 20rem;
        line-height: 150px;
    }
}

</style>
