var render = function render(){
  var _vm$headRowAttrs, _vm$headDefaultAttrs, _vm$headLeftAttrs, _vm$headCenterAttrs, _vm$headRightAttrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-head"
  }, [_c('v-container', [_c('v-row', _vm._b({
    class: (_vm$headRowAttrs = _vm.headRowAttrs) === null || _vm$headRowAttrs === void 0 ? void 0 : _vm$headRowAttrs.className
  }, 'v-row', Object.assign({}, _vm.headRowAttrs), false), [this.$slots['default'] ? _c('v-col', _vm._b({
    class: (_vm$headDefaultAttrs = _vm.headDefaultAttrs) === null || _vm$headDefaultAttrs === void 0 ? void 0 : _vm$headDefaultAttrs.className
  }, 'v-col', Object.assign({}, _vm.headDefaultAttrs), false), [_vm._t("default")], 2) : _vm._e(), this.$slots['left'] ? _c('v-col', _vm._b({
    class: (_vm$headLeftAttrs = _vm.headLeftAttrs) === null || _vm$headLeftAttrs === void 0 ? void 0 : _vm$headLeftAttrs.className
  }, 'v-col', Object.assign({}, _vm.headLeftAttrs), false), [_vm._t("left")], 2) : _vm._e(), this.$slots['center'] ? _c('v-col', _vm._b({
    class: (_vm$headCenterAttrs = _vm.headCenterAttrs) === null || _vm$headCenterAttrs === void 0 ? void 0 : _vm$headCenterAttrs.className
  }, 'v-col', Object.assign({}, _vm.headCenterAttrs), false), [_vm._t("center")], 2) : _vm._e(), this.$slots['right'] ? _c('v-col', _vm._b({
    class: (_vm$headRightAttrs = _vm.headRightAttrs) === null || _vm$headRightAttrs === void 0 ? void 0 : _vm$headRightAttrs.className
  }, 'v-col', Object.assign({}, _vm.headRightAttrs), false), [_vm._t("right")], 2) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }