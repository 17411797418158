<template>
    <!-- S: Mobile Navigation -->
    <u-mobile-nav-basic>
        <u-mobile-nav-head-basic>
            <v-row no-gutters align="center">
                <v-spacer />
                <v-col cols="auto">
                    <btn-hamburger-primary class="btn-hamburger--mobile active"><span class="d-sr-only">모바일 메뉴 닫기</span></btn-hamburger-primary>
                </v-col>
            </v-row>
        </u-mobile-nav-head-basic>
        <u-mobile-nav-body-basic>
            <quick-menu quickName="quick-menu-mo" />
            <gnb gnbName="gnb-mo" />
        </u-mobile-nav-body-basic>
    </u-mobile-nav-basic>
</template>

<script>
import LanguageItemsSecondary from "@/components/publish/parents/headers/language-items-secondary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import QuickMenu from "@/sets/styles/quick-menu/quick-menu.vue";
import UMobileNavBasic from "@/sets/styles/headers/u-mobile-nav-basic.vue";
import UMobileNavHeadBasic from "@/sets/styles/headers/u-mobile-nav-head-basic.vue";
import UMobileNavBodyBasic from "@/sets/styles/headers/u-mobile-nav-body-basic.vue";

import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    components: {
        LanguageItemsSecondary,
        BtnHamburgerPrimary,
        QuickMenu,
        UMobileNavBasic,
        UMobileNavHeadBasic,
        UMobileNavBodyBasic,

        Gnb,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
    },
};
</script>
