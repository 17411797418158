<template>
    <mobile-nav-head class="mobile-nav-head--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-nav-head>
</template>

<script>
import MobileNavHead from "@/sets/styles/headers/mobile-nav-head.vue";

export default {
    props: {},
    components: {
        MobileNavHead,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-nav-head--basic {
    background-color: var(--v-grey-darken4);
    border-bottom: 1px solid var(--v-grey-darken3);
    flex: 0 0 auto;
}
</style>
