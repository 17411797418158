import axios from "@/plugins/axios";

let url = "/api/console/lawyers";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`, data).then(result => result.data);
    },

    postThumb({ _id, type }, image) {
        var formData = new FormData();
        var headers = { "Content-Type": "multipart/form-data" };
        if(type) formData.append("type",  type);
        formData.append("image", image);
        return axios.post(`${url}/${_id}/image`, formData, { headers }).then(result => result.data);
    },
}
