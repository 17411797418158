<template>
    <v-select v-model="searchKey" v-bind="{ ...attrs_select_common, ...$attrs }" filled class="rounded-0" @change="string">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-select>
</template>

<script>
import { attrs_select_common } from "@/assets/variables";

export default {
    props: {
    },
    components: {},
    data: () => {
        return {
            searchKey: "subject",
            attrs_select_common,
        };
    },
    mounted() {},
    methods: {
        string(searchKey) {
            this.$emit("searchKey", searchKey)
        }
    },
};
</script>

<style lang="scss" scoped></style>
