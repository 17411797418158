import { render, staticRenderFns } from "./language-items-primary.vue?vue&type=template&id=746c2d5e&scoped=true"
import script from "./language-items-primary.vue?vue&type=script&lang=js"
export * from "./language-items-primary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746c2d5e",
  null
  
)

export default component.exports