<template>
    <u-btn-icon-outlined-tile v-bind="{ color, ...$attrs }" v-scroll="onScroll" fixed color="grey" class="btn-top-primary" @click="$vuetify.goTo(target, options)">
        <div class="line-height-0">
            <icon-arrow-primary size="small" direction="up" />
        </div>
    </u-btn-icon-outlined-tile>
</template>

<script>
import UBtnIconOutlinedTile from "@/components/publish/styles/buttons/u-btn-icon-outlined-tile.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {
        UBtnIconOutlinedTile,
        IconArrowPrimary,
    },
    data: () => {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    mounted() {},
    methods: {
        onScroll: function () {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var scrollTopBtn = document.querySelector(".btn-top-primary");
            if (scrollTopBtn !== null) {
                if (scrollTop > window.innerHeight / 2) {
                    scrollTopBtn.classList.add("active");
                } else {
                    scrollTopBtn.classList.remove("active");
                }

                var innerHeight = window.innerHeight;
                var scrollHeight = document.body.scrollHeight;
                if (scrollTop + innerHeight >= scrollHeight) {
                    scrollTopBtn.classList.add("bottom");
                } else {
                    scrollTopBtn.classList.remove("bottom");
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: all ease-out 0.2s;
    box-shadow: none !important;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .btn-top-primary {
        bottom: var(--btn-top-position-bottom) !important;
        right: var(--btn-top-position-right) !important;
        &.v-btn.v-size--default.v-btn--icon{
            width: 60px !important;
            height: 60px !important;   
        }
    }
}
@media (min-width: 1200px) {
}
</style>
