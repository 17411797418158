export const PRIVATE_SALES_TABS = {
    LIST: { value: "LIST", text: "작품목록" },
    ABOUT: { value: "ABOUT", text: "Private Sales이란?" },
    MERIT: { value: "MERIT", text: "Private Sales의 좋은점" },
    KATA: { value: "KATA", text: "옥션의 강점" },
    GENRE: { value: "GENRE", text: "장르와 전제조건" },
};

export const AUCTION_PROGRAM_HALLS = {
    AUCTION_A: { value: "A", text: "Online Auction" },
    AUCTION_B: { value: "B", text: "After Sale Online" },
};

export const WORK_CLASSIFICATION_TYPES = {
    NULL: { text: "없음", value: "work_classification_type:NULL" },
    DESC: { text: "텍스트", value: "work_classification_type:DESC" },
    LIST: { text: "리스트", value: "work_classification_type:LIST" },
};

export const WORK_AUCTION_MODES = {
    NULL: { text: "없음", value: "work_mode_auction_type:NULL" },
    PRIVATE: { text: "프라이빗", value: "work_mode_auction_type:PRIVATE" },
    PROGRAM: { text: "프로그램", value: "work_mode_auction_type:PROGRAM" },
};

export const USER_TYPES = {
    PERSON: { text: "일반회원", value: "PERSON" },
    COMPANY: { text: "기업회원", value: "COMPANY" },
};

export const USER_NOTIFY_METHODS = {
    SMS: { text: "SMS(문자메시지)", value: "user.notification:SMS" },
    EMAIL: { text: "이메일", value: "user.notification:EMAIL" },
    PHONE: { text: "전화", value: "user.notification:PHONE" },
};

const man = 10000;
export const BID_UNIT_POLICIES = [
    { value: 5 * man, test: (value) => value < 100 * man, text: "100만원 미만" },
    { value: 10 * man, test: (value) => value < 300 * man, text: "100만원 이상 ~ 300만원 미만" },
    { value: 20 * man, test: (value) => value < 500 * man, text: "300만원 이상 ~ 500만원 미만" },
    { value: 30 * man, test: (value) => value < 1000 * man, text: "500만원 이상 ~ 1,000만원 미만" },
    { value: 50 * man, test: (value) => value < 3000 * man, text: "1,000만원 이상 ~ 3,000만원 미만" },
    { value: 100 * man, test: (value) => value < 5000 * man, text: "3,000만원 이상 ~ 5,000만원 미만" },
    { value: 200 * man, test: (value) => value < 10000 * man, text: "5,000만원 이상 ~ 1억원 미만" },
    { value: 300 * man, test: (value) => value < 20000 * man, text: "1억원 이상 ~ 2억원 미만" },
    { value: 500 * man, test: (value) => value < 50000 * man, text: "2억원 이상 ~ 5억원 미만" },
    { value: 1000 * man, test: (value) => value >= 50000 * man, text: "5억원 이상" },
];

export const DELIVERY_POLICIES_DOMESTIC = [
    { value: 50000, text: "서울", test: (value) => [/^서울/].some((item) => item.test(value)) },
    { value: 100000, text: "수도권", test: (value) => [/^경기/, /^인천/].some((item) => item.test(value)) },
    { value: 150000, text: "그 외 국내지역", test: () => true },
];

export const BOARD_YOUTUBE_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "디테일 미술사(작품탐구)" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_REPORT_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "양식 미술사" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_FORMS_CATEGORIES = {
    AUCTION_FORMS: { value: "AUCTION_FORMS", text: "경매 서식" },
    AUCTION_INFSO: { value: "AUCTION_INFOS", text: "경매 정보" },
    ART_NARRATIVE: { value: "ART_NARRATIVE", text: "미술 이야기" },
    CONSULTATIONS: { value: "CONSULTATIONS", text: "아트 컨설팅" },
};

export const FOOTER_SNS_TYPES = {
    twitter: { text: "트위터", value: "twitter" },
    facebook: { text: "페이스북", value: "facebook" },
    instagram: { text: "인스타그램", value: "instagram" },
};

export const MEMBER_TYPES = {
    LAWYER: { text: "변호사", value: "LAWYER" },
    ADVISORY: { text: "자문단", value: "ADVISORY" },
}