var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("O")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("ur Business")];
      },
      proxy: true
    }, {
      key: "mainSectionFoot",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, _vm._l(_vm.business, function (item) {
          return _c('v-col', {
            key: item.title,
            attrs: {
              "cols": "12",
              "sm": "6",
              "md": "4"
            }
          }, [_c('v-card', {
            staticClass: "v-card--none-active-bg plus-btn-card h-100",
            attrs: {
              "href": item.link,
              "target": "_blank",
              "tile": "",
              "flat": "",
              "dark": ""
            }
          }, [_c('hover-line', {
            attrs: {
              "lineNone": !item.link
            }
          }, [_c('v-img', {
            staticClass: "w-100 h-100",
            attrs: {
              "src": item.image,
              "aspect-ratio": 640 / 300
            }
          }, [_c('div', {
            staticClass: "h-100 pa-16px pa-md-24px pa-lg-36px card-inner"
          }, [_c('div', [_c('u-tit-default', {
            staticClass: "tit--sm line-height-14 font-weight-bold",
            attrs: {
              "data-aos": "fade-up"
            }
          }, [_vm._v(_vm._s(item.title))]), _c('u-tit-default', {
            staticClass: "tit--sm mt-8px mt-md-16px",
            attrs: {
              "data-aos": "fade-up",
              "data-aos-delay": "200"
            }
          }, [_vm._v(_vm._s(item.subTitle))])], 1), item.link ? _c('div', {
            staticClass: "icon-plus-wrap"
          }, [_c('div', {
            attrs: {
              "data-aos": "fade-up",
              "data-aos-delay": "400"
            }
          }, [_c('icon-plus')], 1)]) : _vm._e()])])], 1)], 1)], 1);
        }), 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }