var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon icon-close",
    class: [_vm.size ? 'icon--size-' + _vm.size : '']
  });

}
var staticRenderFns = []

export { render, staticRenderFns }