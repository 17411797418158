<template>
    <quick-menu-fixed-bar />
</template>

<script>
import QuickMenuFixedBar from "@/sets/styles/quick-menu/quick-menu-fixed-bar.vue";

export default {
    components: {
        QuickMenuFixedBar,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
    },
};
</script>
