<template>
    <main-section class="main-section--media">
        <v-row>
            <v-col cols="12" md="5" class="main-section--media__left">
                <div class="h-100 d-flex flex-md-column align-end align-md-start justify-space-between">
                    <tit-wrap-primary class="lawyer-slide__title pb-0">
                        <template #TitHead>M</template>edia
                    </tit-wrap-primary>
                    <div data-aos="zoom-out" data-aos-delay="500" class="media-slide-btn">
                        <swiper-button justify="end"/>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="7" class="mt-30px mt-md-0">
                <div data-aos="fade-up" class="media-contents">
                    <swiper ref="swiperMedia" :options="swiperOptionMedia">
                        <!-- <swiper-slide v-for="item in media" :key="item.title" class="swiper swiper--media">
                            <v-card tile flat class="media-card v-card--none-active-bg w-100 transparent" @click="open(item.video)">
                                <v-responsive :aspect-ratio="830 / 466" class="media-card__image w-100" :style="'background-image : url('+item.image+')'" />
                            </v-card>
                        </swiper-slide> -->
                        <swiper-slide v-for="item in medias" :key="item.rank" class="swiper swiper--media">
                            <v-card tile flat class="media-card v-card--none-active-bg w-100 transparent" @click="open(item.videoId)">
                                <v-responsive :aspect-ratio="830 / 466" class="media-card__image w-100" :style="'background-image : url('+item.thumbnail+')'" />
                            </v-card>
                        </swiper-slide>
                    </swiper>
                </div>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="show" eager fullscreen content-class="mw-100 h-100" max-width="100%" width="100%">
            <v-btn x-large absolute top right icon text color="white" style="z-index: 10;" @click="close">
                <icon-close />
            </v-btn>
            <v-responsive max-width="1440" :aspect-ratio="1440 / 810">
                <iframe :src="'https://www.youtube.com/embed/'+selectedVideo" class="w-100 h-100 d-block" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </v-responsive>
        </v-bottom-sheet>
    </main-section>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSection from "@/sets/styles/mains/main-section.vue";
import SwiperButton from "@/components/publish/styles/swiper/swiper-button.vue";
import IconClose from "@/components/publish/styles/icons/icon-close.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSection,
        SwiperButton,
        IconClose,
        TitWrapPrimary,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            show: false,
            selectedVideo: "",
            swiperOptionMedia: {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 12,
                speed: 500,
                navigation: {
                    nextEl: ".media-slide-btn .swiper-btn-next",
                    prevEl: ".media-slide-btn .swiper-btn-prev",
                },
                breakpoints: {
                    1024:{
                        spaceBetween: 80,
                    },
                },
            },
            medias: []
            // media: [
            //     {
            //         image : "/images/main/media-img.jpg",
            //         video : "pUNIKFGAoAM",
            //     },
            //     {
            //         image : "/images/main/media-img2.jpg",
            //         video : "hJ431OEvf00",
            //     },
            //     {
            //         image : "/images/main/media-img3.jpg",
            //         video : "UqyCPI9TxPY",
            //     },
            //     {
            //         image : "/images/main/media-img4.jpg",
            //         video : "sQUMakLjHHU",
            //     },
            // ]
        }; 
    },
    mounted(){
        this.search();
    },
    methods:{
        async search(){
            const { summary, medias } = await api.v1.media.gets({});
            this.medias = medias;
        },
        open(video){
            this.show = true;            
            this.selectedVideo = video;
        },
        close(){
            this.show = false;
            this.selectedVideo = "";
        }
    }
}
</script>

<style lang="scss" scoped>
.main-section--media{
    background-image: url(/images/main/media-bg.svg);
    background-position: center calc(var(--page-section-padding-y) / 2);
    background-repeat: no-repeat;
    background-size: 100% auto;
    &__left{
        position: relative;
        z-index: 5;
    }
}
::v-deep{
    .v-bottom-sheet{
        background-color: rgba(0, 0, 0, .8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
    .swiper-slide{
        transition: 0.25s ease-out;
        .media-card__image{
            transform-origin: left center;
            transition: 0.25s ease-out;
        } 
    }
    .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next){
        opacity: 0;
        visibility: hidden;           
    }
    .swiper-slide:not(.swiper-slide-active){
        .media-card__image{
            transform: scale(0.66);
        }                
    }
}
    
.media-contents{
    position: relative;
    ::v-deep{
        .swiper-slide:not(.swiper-slide-active){
            cursor: default;
            pointer-events: none;
        }
    }
}

.media-card{
    position: relative;
    &__image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 100%;
        height: 100%;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(0, 0, 0, .6);
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 48px;
            height: 48px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url(/images/icon/icon-play.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .main-section--media{
        background-position: center;
    }
    ::v-deep{
        .v-bottom-sheet{
            .v-btn--absolute.v-btn--top.v-btn--right{
                top: 50px;
                right: 50px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){ 
    .media-contents{
        position: relative;
        ::v-deep{
            .swiper-container{
                overflow: visible;
                margin: 0;
            }
        }
    }
}
</style>