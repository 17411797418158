<template>
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-blog" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <g clip-path="url(#clip0_795_9745)">
            <path d="M38.7899 18.8696H21.2101C19.4368 18.8696 18 20.3064 18 22.0798V32.5127C18 34.2861 19.4368 35.7229 21.2101 35.7229H27.4185L29.53 40.6983C29.53 40.6983 29.6756 41.1303 30.0267 41.1303C30.3794 41.1303 30.5234 40.6983 30.5234 40.6983L32.6349 35.7229H38.7866C40.56 35.7229 41.9968 34.2861 41.9968 32.5127V22.0798C41.9968 20.3064 40.56 18.8696 38.7866 18.8696H38.7899ZM25.5399 28.0923C25.5302 29.9999 23.5579 30.0533 23.5579 30.0533C22.7958 30.0533 22.3654 29.5485 22.3654 29.5485V29.8915H20.9302V23.9696C20.9302 23.9696 20.9238 23.968 20.9302 23.9664V23.9696C21.0192 23.9842 22.3654 23.9664 22.3654 23.9664V26.2316C22.7036 25.6556 23.6792 25.6653 23.6792 25.6653C25.8053 25.8627 25.5399 28.0923 25.5399 28.0923ZM28.0673 25.8465V29.9012H26.6612V25.887C26.6612 25.3401 25.9833 25.1896 25.9833 25.1896V23.7738C28.2275 23.8644 28.0657 25.8465 28.0657 25.8465H28.0673ZM31.3988 30.0566C30.0979 30.0566 29.0429 29.0858 29.0429 27.8868C29.0429 26.6879 30.0979 25.7171 31.3988 25.7171C32.6997 25.7171 33.7546 26.6879 33.7546 27.8868C33.7546 29.0858 32.6997 30.0566 31.3988 30.0566ZM39.0989 30.0566C39.0989 30.0566 39.1183 32.1163 37.0262 32.1163H36.3887V30.7814H36.7722C36.7722 30.7814 37.6524 30.8623 37.6411 29.5065C37.6411 29.5065 37.4696 30.055 36.235 30.055C36.235 30.055 34.4859 29.9288 34.4859 28.2217V27.5745C34.4859 27.5745 34.5264 25.8044 36.447 25.6637C36.447 25.6637 37.2058 25.5828 37.6702 26.2106V25.7753H39.0957V30.055L39.0989 30.0566Z"/>
            <path d="M23.2331 28.7378C23.6897 28.7378 24.0599 28.3677 24.0599 27.911C24.0599 27.4544 23.6897 27.0842 23.2331 27.0842C22.7764 27.0842 22.4062 27.4544 22.4062 27.911C22.4062 28.3677 22.7764 28.7378 23.2331 28.7378Z"/>
            <path d="M31.3977 28.7766C31.8892 28.7766 32.2876 28.3782 32.2876 27.8867C32.2876 27.3953 31.8892 26.9968 31.3977 26.9968C30.9062 26.9968 30.5078 27.3953 30.5078 27.8867C30.5078 28.3782 30.9062 28.7766 31.3977 28.7766Z"/>
            <path d="M36.8611 28.7558C37.3409 28.7558 37.7299 28.3668 37.7299 27.8869C37.7299 27.4071 37.3409 27.0181 36.8611 27.0181C36.3812 27.0181 35.9922 27.4071 35.9922 27.8869C35.9922 28.3668 36.3812 28.7558 36.8611 28.7558Z"/>
        </g>
        <defs>
        <clipPath id="clip0_795_9745">
            <rect width="23.9968" height="22.2606" transform="translate(18 18.8696)"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
}
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 60px;
    }
}
</style>