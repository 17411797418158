var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-btn swiper-btn-prev",
    attrs: {
      "color": "primary lighten-4",
      "block": "",
      "dark": "",
      "fab": "",
      "tile": ""
    }
  }, [_c('icon-arrow-primary', {
    staticClass: "white--text"
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-btn swiper-btn-next",
    attrs: {
      "color": "primary lighten-3",
      "block": "",
      "dark": "",
      "fab": "",
      "tile": ""
    }
  }, [_c('icon-arrow-primary', {
    staticClass: "white--text",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }