<template>
    <gnb v-bind="{ gnbName, gnbClass, lnbTag }" gnbClass="gnb--filled">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </gnb>
</template>

<script>
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,
    },
    components: {
        Gnb,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .gnb--filled {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        color: #fff;
                        background-color: var(--v-primary-base);
                    }
                }
            }
        }
    }
}
</style>
